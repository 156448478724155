const reduxActionTypes = {
    SETUSER: 'SETUSER',
    UPDATEUSER: 'UPDATEUSER',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SETPRODUCTS: 'SETPRODUCTS',
    SETLIKE: 'SETLIKE',
    // SETSORTBY: 'SETSORTBY',
    // SETSEARCHQUERY: 'SETSEARCHQUERY',
    // SETFILTERCATEGORY: 'SETFILTERCATEGORY',
    // SETFILTERSUBCATEGORY: 'SETFILTERSUBCATEGORY',
    SETPRODUCTSCOUNT: 'SETPRODUCTSCOUNT',
    SETPRODUCTSNAME: 'SETPRODUCTSNAME',
    SETOPENEDPAGE: 'SETOPENEDPAGE',
    SETFAVORITEPRODUCT: 'SETFAVORITEPRODUCT',
    REMOVEPRODUCTFROMFAVORITE: 'REMOVEPRODUCTFROMFAVORITE',
    SETCARTPRODUCT: 'SETCARTPRODUCT',
    REMOVEPRODUCTFROMCART: 'REMOVEPRODUCTFROMCART',
    SETUSERTRANSACTION: 'SETUSERTRANSACTION',

    SETFAVORITECOUNT: 'SETFAVORITECOUNT',
    SETSLIDER: 'SETSLIDER',
    // INCFAVORITECOUNT: 'INCFAVORITECOUNT',
    // DECFAVORITECOUNT: 'DECFAVORITECOUNT',

    SETCARTCOUNT: 'SETCARTCOUNT',
    INCCARTCOUNT: 'INCCARTCOUNT',
    // SETMAINCATEGORIES: 'SETMAINCATEGORIES'
    SETCATEGORIES: 'SETMAINCATEGORIES'
    
}

export default reduxActionTypes