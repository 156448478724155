import React from 'react'
import { Col } from 'react-bootstrap'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const ProductCartMobileSceleton = () => {


    return (
        <Col xs="12" className="  justify-content-center d-block d-sm-none" style={{
            borderRadius: "10px",
            marginTop: "10px",
            paddingInline: "0px"
        }}>
            <div className='parent-productCartMobile p-2'>
                <div className='' style={{
                    flex: "2",
                    paddingRight: "5px"
                }}>
                    <SkeletonElement type="avatarmobile" />
                </div>
                <div className='' 
                style={{
                    flex: "5",
                    paddingLeft:" 5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div><SkeletonElement type="title" /></div>
                    <div className='d-flex justify-content-between align-items-center ' >
                        
                            <SkeletonElement type="small-box-mobile" />
                     
                            <SkeletonElement type="small-box-mobile" />
                       
                            <SkeletonElement type="small-box-mobile" />
                        
                    </div>
                </div>  <Shimmer />
            </div>

        </Col>
    )
}

export default ProductCartMobileSceleton