import React, { useState } from 'react'
import './searchbar.css'
import rass from '../../../assets/icon/search.png'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

// const suggestions = ["Apple", "Banana", "Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry","Cherry", "Date", "Grape", "Lemon", "Orange", "Peach", "Pear", "Strawberry"];

const SearchBar = () => {

  const [searchParms, setSearchParms] = useSearchParams()
  const suggestions = useSelector(state => state.productsName)
  // const [searchInput, setSearchInput] = useState('');
  // const searchQuery = useSelector(state => state.searchQuery);
  const searchQuery = searchParms.get("searchQuery")
  const dispatch = useDispatch();
  const [searchValue, setsearchValue] = useState('')
  const setSearchInput = (value) => {
    // dispatch({ type: reduxActionTypes.SETSEARCHQUERY, searchQuery: value })
    setsearchValue(value)
  }
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInputChange = (event) => {
    const userInput = event.target.value.toLowerCase();
    const filtered = userInput ? suggestions.filter(suggestion =>
      suggestion.name.toLowerCase().includes(userInput)
    ) : []
    setSearchInput(event.target.value);
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setFilteredSuggestions([]);

  };

  const handleSearchSubmit = () => {
    // dispatch({ type: reduxActionTypes.SETSEARCHQUERY, searchQuery: searchValue })
    // dispatch({ type: reduxActionTypes.SETFILTERCATEGORY, filterCategory: "ALL" })
    // dispatch({ type: reduxActionTypes.SETFILTERSUBCATEGORY, filterSubCategory: "ALL" })
    setSearchParms(prev => {
      prev.set("searchQuery", searchValue)
      prev.set("filterCategory", "ALL")
      prev.set("filterSubCategory", "ALL")
      return prev
    }, { replace: true })
    setFilteredSuggestions([])
  }

  useEffect(() => {
    setsearchValue(searchQuery)
  }, [searchQuery])

  return (
    <div className="SearchBar">
      <div className="search-container">
        <img src={rass} alt="Search Icon" className="search-icon" />
        <input
          type="text"
          value={searchValue}
          onChange={handleInputChange}
          className="search-input"
          placeholder="What you are looking for?"
          onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}

        />
        {filteredSuggestions.length !== 0 && <div className="autocomplete-list">
          {filteredSuggestions.map((suggestion, index) => (
            <Link to={"/products/" + suggestion?._id} >  <div
              key={index}
              className="autocomplete-item"
              // onClick={() => handleSuggestionClick(suggestion._id)}
              style={{ color: '#000' }}
            >
              {suggestion.name}
            </div></Link>
          ))}
        </div>}
      </div>
    </div>

  )
}

export default SearchBar
