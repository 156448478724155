import React, { useState } from 'react'

const AddSlider = () => {

    const [title, settitle] = useState('discounntttt')
    const [description, setdescription] = useState('50% sale')
    const [color, setcolor] = useState('#ffe124')
    const [display, setdisplay] = useState(true)

    const [uploading, setuploading] = useState(false)

    
    const upload = (e) => {
        e.preventDefault()

        const fileInput = document.getElementById('file')
        const files = fileInput.files
        if (!files) {
            console.log('No file selected!')
            return
        }

        const formData = new FormData();

        formData.append("title", title)
        formData.append("description", description)
        formData.append("color", color)
        formData.append("display", display)
        formData.append("file", files[0]);
        

        const url = window.location.protocol + "//" + window.location.hostname + ":4000/api/addToSlider"
        console.log('uploading ' + files.length + " files")
        setuploading(true)
        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                // alert(data)
                // const urlElement = document.getElementById('url')
                // data?.urls.map(url => {
                //   const image = document.createElement('img')
                //   image.src = url
                //   image.height = 200
                //   urlElement.appendChild(image)
                //   setuploading(false)
                //   // console.log("Upload successful:", data);
                // })
                setuploading(false)
            })
            .catch(error => {
                console.error("Error uploading file:", error);
                setuploading(false)
            })
        // .finally(() => setuploading(false))
    }


    return (
        <form id="form" onSubmit={upload} className=' pb-5'>
            <div><label htmlFor="">title</label><input value={title} onChange={(e) => settitle(e.target.value)} /></div>
            <div><label htmlFor="">description</label><input value={description} onChange={(e) => setdescription(e.target.value)} /></div>
            <div><label htmlFor="">color</label><input value={color} onChange={(e) => setcolor(e.target.value)} /></div>
            <div><label htmlFor="">display</label><input value={display} onChange={(e) => setdisplay(e.target.value.to)} /></div>
            <div><input type="file" id="file" />
                <input type="submit" value={uploading ? 'uploading...' : "Send"} />
            </div>
        </form>
    )
}
export default AddSlider
