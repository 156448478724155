import React, { useState } from 'react'

const AddProductPage = () => {
    const [name, setname] = useState('p163')
    const [description, setdescription] = useState('lorem1 djfjb djvdvjfb dfdjvbd fdfjd')
    const [size, setsize] = useState('10*10')
    const [color, setcolor] = useState('red')
    const [material, setmaterial] = useState('copper')
    const [price, setprice] = useState(10)
    const [quantity, setquantity] = useState(4)
    const [category, setcategory] = useState("651426b8852f4ad108aa5caa")
    const [categoryKey, setcategoryKey] = useState("104")

    const [uploading, setuploading] = useState(false)

    const upload = (e) => {
        e.preventDefault()

        const fileInput = document.getElementById('file')
        const files = fileInput.files
        if (files.length === 0) {
            console.log('No file selected!')
            return
        }

        const formData = new FormData();

        formData.append("name", name)
        formData.append("description", description)
        formData.append("quantity", quantity)
        formData.append("size", [size])
        formData.append("color", [color])
        formData.append("material", [material])
        formData.append("price", [JSON.stringify({size, color, material, priceValue: price})])
        formData.append("category", category)
        formData.append("categoryKey", categoryKey)

        for (const i in files) {
            formData.append("file", files[i]);
        }

        const url = window.location.protocol + "//" + window.location.hostname + ":4000/api/addProduct"
        console.log('uploading ' + files.length + " files")
        setuploading(true)
        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                // alert(data)
                // const urlElement = document.getElementById('url')
                // data?.urls.map(url => {
                //   const image = document.createElement('img')
                //   image.src = url
                //   image.height = 200
                //   urlElement.appendChild(image)
                //   setuploading(false)
                //   // console.log("Upload successful:", data);
                // })
                setuploading(false)
            })
            .catch(error => {
                console.error("Error uploading file:", error);
                setuploading(false)
            })
        // .finally(() => setuploading(false))
    }


    return (
        <form id="form" onSubmit={upload} className=' pb-5'>
            <div><label htmlFor="">name</label><input value={name} onChange={(e) => setname(e.target.value)} /></div>
            <div><label htmlFor="">description</label><input value={description} onChange={(e) => setdescription(e.target.value)} /></div>
            <div><label htmlFor="">size</label><input value={size} onChange={(e) => setsize(e.target.value)} /></div>
            <div><label htmlFor="">color</label><input value={color} onChange={(e) => setcolor(e.target.value)} /></div>
            <div><label htmlFor="">material</label><input value={material} onChange={(e) => setmaterial(e.target.value)} /></div>
            <div><label htmlFor="">price</label><input value={price} onChange={(e) => setprice(e.target.value)} /></div>
            <div><label htmlFor="">quantity</label><input value={quantity} onChange={(e) => setquantity(e.target.value)} /></div>
            <div><label htmlFor="">category</label><input value={category} onChange={(e) => setcategory(e.target.value)} /></div>
            <div><label htmlFor="">category key</label><input value={categoryKey} onChange={(e) => setcategoryKey(e.target.value)} /></div>
            <div><input type="file" id="file" multiple />
                <input type="submit" value={uploading ? 'uploading...' : "Send"} />
            </div>
        </form>
    )
}

export default AddProductPage
