import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import UserProfile from '../../Component/profile/userProfile/UserProfile'
import { getUser } from '../../actions/profileAction'
import reduxActionTypes from '../../enums/reduxActionTypes'
// import GuestNavbar from '../../Component/NavBar/GuestNavBar'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import { setOpenedPage } from '../../actions/urlService'
import pageEnum from '../../enums/pageEnum'


const ProfilePage = () => {
    const cookie = Cookies.get("token")
    const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    const userTransaction = useSelector(state => state.userTransaction)
    useEffect(() => {
        setOpenedPage(pageEnum.PROFILEPAGE, dispatch)
        if (cookie) {
            getUser()
                .then(
                    resolve => {
                        // console.log(resolve.data)
                        dispatch({ type: reduxActionTypes.SETUSER, user: resolve.data })
                    },
                    reject => console.log(reject.msg)
                )
                .catch(error => console.error(error))
        }
        // console.log(user)

        return () => {

        }
    }, [])

    return (
        <>
            <StoreNavBar />
            <Container style={{ marginTop: '80px', minHeight: "600px" }}>
                <Row className='py-3'>
                    <Col xs="12">
                        <UserProfile />
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default ProfilePage