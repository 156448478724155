

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Component/Footer/Footer';
import MainHomePage from './Page/MainHome/MainHomePage';
import ProductDetalisPage from './Page/MainHome/ProductDetalisPage';
import SignUpPage from './Page/SignUp/SignUpPage';
import ShopProductsPage from './Page/Store/ShopProductsPage';
import StorePage1 from './Page/Store/StorePage1';
import ProfilePage from './Page/profile/ProfilePage';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Whatsapp from './Component/whatsapp/Whatsapp';
import StorePage2 from './Page/Store/StorePage2';
import CartPage from './Page/cart/CartPage';
import FavoritePage from './Page/favorite/FavoritePage';
import ServicesPage from './Page/services/ServicesPage';
import TransactionPage from './Page/transaction/TransactionPage';
import { authenticateToken } from './actions/signUpActions';
import { getAllCategories, getAllProductsTitles, getCartCount, getFavoriteCount } from './actions/storeAction';
import AddProductPage from './admin/AddProductPage';
import AddSlider from './admin/AddSlider';
import reduxActionTypes from './enums/reduxActionTypes';

const App = () => {

  const dispatch = useDispatch()
  const isLogedin = useSelector(state => state.isLogedin)

  useEffect(() => {
    getAllProductsTitles().then(
      resolve => {
        dispatch({ type: reduxActionTypes.SETPRODUCTSNAME, value: resolve.data })
        // console.log('resolve.data', resolve.data)

      },
      reject => {
        dispatch({ type: reduxActionTypes.SETPRODUCTSNAME, value: reject.data })
        console.log('reject.data', reject.data)
      }
    )
      .catch((error) => {
        console.log('error', error)
      })
  }, [])

  useEffect(() => {
    authenticateToken()
      .then(
        resolve => {
          dispatch({ type: reduxActionTypes.LOGIN })

          isLogedin && getFavoriteCount()
            .then(resolve => {
              // console.log('resolve.data', resolve.data)
              dispatch({ type: reduxActionTypes.SETFAVORITECOUNT, value: resolve.data?.count })
            })
            .catch(error => console.error(error))

          isLogedin && getCartCount()
            .then(resolve => {
              // console.log('resolve.data', resolve.data)
              dispatch({ type: reduxActionTypes.SETCARTCOUNT, value: resolve.data?.count })
            })
            .catch(error => console.error(error))
        },
        reject => {
          console.log(reject.msg)
        }
      )
      .catch(error => console.error(error))

    // getMainCategories()
    //   .then(res => {
    //     // console.log('MAin categories:', res)
    //     dispatch({
    //       type: reduxActionTypes.SETMAINCATEGORIES, value: [{
    //         _id: "ALL",
    //         image: { url: img_7 },
    //         name: "All ",
    //         descreption: ""
    //       }, ...res]
    //     })
    //   })
    //   .catch(error => console.error(error))

    getAllCategories()
      .then(res => {
        // console.log('MAin categories:', res)
        dispatch({ type: reduxActionTypes.SETCATEGORIES, value: res })
      })
      .catch(error => console.error(error))


    return () => {
    }
  }, [isLogedin])

  return (
    <div className="App">

      {/* <GuestNavbar /> */}
      {/* <StoreNavbar /> */}
      <Routes>
        <Route path="/" element={<MainHomePage />} />
        <Route path="/login" element={<SignUpPage />} />
        <Route path="/store/:category" element={<StorePage1 />} />
        <Route path="/products/:productId" element={<ProductDetalisPage />} />
        <Route path={"/store/PV/:subCat"} element={<ShopProductsPage />} />
        <Route path={"/profile"} element={<ProfilePage />} />

        <Route path={"/StorePage2"} element={<StorePage2 />} />
        <Route path={"/FavoritePage"} element={<FavoritePage />} />
        <Route path={"/CartPage"} element={<CartPage />} />
        <Route path={"/transactionPage"} element={<TransactionPage />} />
        <Route path={"/addProductPage"} element={<AddProductPage />} />
        <Route path={"/AddSlider"} element={<AddSlider />} />
        <Route path={"/ServicesPage"} element={<ServicesPage />} />
      </Routes>
      <Footer />
      <Whatsapp />

    </div>
  );
}

export default App;

