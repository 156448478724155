const pricingMethod = {  ///MSC
    STATIC: "STATIC",
    COLOR: "COLOR",
    SIZE: "SIZE",
    SIZE_COLOR: "SIZE_COLOR",
    MATERIAL: "MATERIAL",
    MATERIAL_COLOR: "MATERIAL_COLOR",
    MATERIAL_SIZE: "MATERIAL_SIZE",
    MATERIAL_SIZE_COLOR: "MATERIAL_SIZE_COLOR",
    QUANTITY: "QUANTITY",
}
// const pricingMethod = [
//     "STATIC",
//     "COLOR",
//     "SIZE",
//     "SIZE_COLOR",
//     "MATERIAL",
//     "MATERIAL_COLOR",
//     "MATERIAL_SIZE",
//     "MATERIAL_SIZE_COLOR"
// ]

export default pricingMethod