import React from "react";
import "./footer.css"
import fb from "../../assets/icon/facebook.png"
import is from "../../assets/icon/insta.png"
import li from "../../assets/icon/Linkedin.png"
import lo from "../../assets/icon/Location-1.png"
import em from "../../assets/icon/email.png"
import lbFlag from '../../assets/icon/lebanese-flag.png'
import phoneIcon from '../../assets/icon/phone-2.png'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import servecies from "../../actions/services";



const Footer = () => {

  const mainCategories = useSelector(state => state.mainCategories)

  return (
    <div className="footer">
      <footer>
        <div class="footer-top">
          <div class="container ">
            <div class="row gy-4 d-flex sectionContainer">

              <div class="col-lg-3">
                <h5 class="text-title">Sitemap</h5>
                <ul class="list-unstyled">
                  <li>
                    <a href="/#heroSection">Home</a>
                    {/* <Link to='/#heroSection'>Home</Link> */}
                  </li>
                  <li>
                    <a href="/#services">Services</a>
                    {/* <Link to='/#services'>Services</Link> */}
                  </li>
                  <li>
                    <a href="/#features">Features</a>
                    {/* <Link to='#features'>Features</Link> */}
                  </li>
                  <li>
                    <a href="/#contact">Contact</a>
                    {/* <Link to='/#contact'>Contact</Link> */}
                  </li>
                </ul>
              </div>
              <div class="col-lg-3">
                <h5 class="text-title">Store</h5>
                <ul class="list-unstyled">
                  {mainCategories.map(mCat => {
                    if (mCat?._id !== "ALL")
                      return <li>
                        <Link to={`/StorePage2?filterCategory=${encodeURIComponent(mCat?._id)}&filterSubCategory=ALL&searchQuery=`} >{mCat?.name}</Link>
                      </li>
                  })}
                  {/* <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li>
                  <li>
                  <Link>hello cat</Link>
                  </li> */}
                </ul>
              </div>
              <div class="col-lg-3">
                <h5 class="text-title">Find Us</h5>
                <ul class="list-unstyled">
                  <li><img src={lbFlag} className="footer-icon-location " /> Lebanon</li>
                  <li><img src={lo} className="footer-icon-location " /> Tripoli, Zahrieh</li>
                  <li><img src={phoneIcon} className="footer-icon-location " /> +961 78 833 781</li>
                  <li><img src={em} className="footer-icon-location " /> info@krayemco.com</li>
                  <li>
                    <div class="col-md-6">
                      <div class="social-icons mt-3">
                        <a href="https://www.facebook.com/Krayemco.org" target="_blank">
                          <img src={fb} className="footer-icon" />
                        </a>
                        <a href="https://instagram.com/krayemco?igshid=MzRlODBiNWFlZA==" target="_blank">
                          <img src={is} className="footer-icon" />
                        </a>
                        <a href="https://www.linkedin.com/in/abdel-aziz-al-masri-2a0732193/" target="_blank">
                          <img src={li} className="footer-icon" />
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="footer-bottom py-3">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <p class="mb-0">
                  © 2023 copyright all right reserved | Designed with 
                  <i class="bx bx-heart text-danger"></i> by
                  <a
                    href=""
                    class="text-white"
                  >
                    &nbsp; Khaled
                  </a>
                </p>
              </div>
              <div class="col-md-6">
                <div class="social-icons">
                  <a href="#">
                    <img src={fb} className="footer-icon"  />
                  </a>
                  <a href="https://instagram.com/krayemco?igshid=MzRlODBiNWFlZA==">
                  <img src={is} className="footer-icon" />
                  </a>
                  <a href="#">
                  <img src={li} className="footer-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
