import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./signup.css";
import { login } from "../../actions/signUpActions";
import { useDispatch } from "react-redux";
import reduxActionType from '../../enums/reduxActionTypes'
import Cookies from "js-cookie";

export default function LogIn() {

  const [msgValidation, setmsgValidation] = useState('')


  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()

  // const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    setmsgValidation("")
    const formData = {
      email,
      password,

    };
    login(formData).then(
      resolve => {
        {
          // dispatch({ type: reduxActionTypes.SETUSER, user: formData })
          // setVerify(true)
          console.log(resolve?.msg)
          dispatch({ type: reduxActionType.LOGIN })
          // Cookies.set('user', JSON.stringify({_id: resolve.data._id, email,password}), {expires: 365})
          Cookies.set('token', resolve.token, { expires: 365 })
          navigate('/')
        }
      },
      reject => {
        console.log(reject?.msg)
        setmsgValidation(reject?.msg)
      }
    ).catch(error => console.error(error))
    // dispatch(loginaction(formData));

  };

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="login">
      <h2 className="mb-4 animate__animated animate__fadeInDown " style={{ color: "#25485c", textShadow: "none" }}>Login</h2>
      <div className="form-group animate__animated animate__fadeInLeft">

        {/* {success && (navigate("/StorePage"))} */}

        {/* {error && (
        <div className="alert alert-danger" role="alert">
          {error.message.split(",")[0]}
        </div>
      )} */}

        <label htmlFor="email" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Email</label>
        <input
          type="email"
          id="email"
          style={{
            marginBottom: "20px", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
            , backgroundColor: "rgb(0,0,0,0.5)", color: "white"
          }}
          className="form-control"
          placeholder="example@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Password</label>
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          style={{
            backgroundColor: "rgb(0,0,0,0.5)"
            , boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white", marginBottom: ""
          }}
          className="form-control"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="showPassword">
          <input
            id="showPassword"
            type="checkbox"
            style={{ width: "20px", height: "20px", color: 'white', marginRight: "5px", cursor: "pointer" }}
            onClick={togglePasswordVisibility}
          />
          <label htmlFor="showPassword" style={{ color: "#25485c", textShadow: "none", fontWeight: "bold", cursor: "pointer" }}> show password</label>
        </div>
        <div>
          <label className="error-message">{msgValidation}</label>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn btn-primary"
          disabled={false}>
          {"Login"}
        </button>

        <div style={{ height: '300px' }}></div>
      </div>
    </div>
  )
}