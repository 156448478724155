import React from 'react'
import './goToStoreButton.css'
import cart from "../../assets/icon/kcart.png"
import { Link, useNavigate } from 'react-router-dom'

const GoToStoreButton = () => {
    const navigate = useNavigate()
    return (
        <div className=' goToStoreBtn'>
            <button className='glowing-btn' onClick={()=> navigate('/StorePage2')}><span className='glowing-txt'>GO &nbsp;<span className='faulty-letter'>TO &nbsp;</span>STORE</span></button>
        </div>
    )
}

export default GoToStoreButton
