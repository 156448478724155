import React from 'react'
import './cartitemmobile.css'
import deleteIcon from '../../assets/icon/delete.png'
import productImage from '../../assets/images/empty_image.png'
import { Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { removeFromCart } from '../../actions/storeAction'
import reduxActionTypes from '../../enums/reduxActionTypes'

const CartItemMobile = ({ item }) => {

    const dispatch = useDispatch();
    const deletFromCart = () => {
        removeFromCart(item?._id)
            .then(resolve => {

                console.log("remove:", resolve)
                dispatch({ type: reduxActionTypes.REMOVEPRODUCTFROMCART, cartId: item._id })
            })
        // console.log(item?._id)
    }

    return (
        <div className='CartItemMobile'>
            <div className=' header'>

                {/* <div className='col-2'><img src={logo} className=' logo'/></div> */}

                <div className=' title' >{item.productName}</div>

            </div>

            <div className=' body row'>
                <div className='text col-8'>
                    <div className='id_color d-flex justify-content-between'><span>#{item.productKey}</span> <span className='cartColor' style={{ backgroundColor: item.color }}></span></div>
                    <div className='sizeMaterial'><span>{item.size}cm of </span><span>{item.material}</span></div>
                    <div className='d-flex justify-content-between'>
                        {/* <input type="number" className='col-4' value={item.quantity} /> */}
                        <div className='cartPrice'>
                            {/* <span className='cartInfoKey'>price: </span> */}
                            {item.productDiscount !== 0 ?
                                <span>
                                    <del className='cartPriceOld'> {item?.productPrice}$ </del>
                                    <span className='cartPriceNew'>{item?.productPrice * (100 - item?.productDiscount) / 100}$</span>
                                </span> :
                                <span>
                                    <span className='cartPriceNew'> {item?.productPrice}$ </span>
                                </span>
                            }
                            <span>x{item.quantity}</span>
                            {/* <span className='cartPriceDiscount' >(-{item?.productDiscount}%)</span> */}
                        </div>
                        <button className='RemoveBtn ' onClick={deletFromCart}>Remove<img src={deleteIcon} className='logo' /></button>
                    </div>
                </div>

                <div className='imageContainer col-4' style={{ borderColor: item?.color }}> <img src={item?.productImage ? item?.productImage : productImage} className='image ' /></div>

            </div>
        </div>
    )
}

export default CartItemMobile
