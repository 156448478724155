import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"
import reduxActionTypes from '../enums/reduxActionTypes'

const signUp = (userData) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/signup'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(userData)
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

const verifyEmail = (userData) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/verify-email'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(userData)
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

const checkEmail = (email) => {

    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/check-email'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({ email })
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)
            })
            .catch(error => console.error(error))
    })

}

const login = (userData) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/login'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(userData)
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

const logout = (dispatch, navigate) => {
    Cookies.remove('token');
    dispatch({ type: reduxActionTypes.LOGOUT })
    navigate("/")
}

const authenticateToken = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/authenticateToken'
        fetch(url, {
        //  credentials:"include"
        headers : {
            Authorization: `Bearer ${Cookies.get('token')}`
        }
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}



export {
    signUp,
    verifyEmail,
    checkEmail,
    login,
    logout,
    authenticateToken
}

