import React from 'react'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const CategoryCartSceleton = () => {
    const themeClass= 'light'

    return (
      <div className={`parent-category ${themeClass} skeleton-category`}>
            <Shimmer />
      </div>
    )
}

export default CategoryCartSceleton
