import React, { useEffect, useState } from 'react'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import './favoritepage.css'
import reduxActionTypes from '../../enums/reduxActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { getAllFavorite } from '../../actions/storeAction'
import ProductCard from '../../Component/store/product/ProductCard'
import { Container, Row } from 'react-bootstrap'
import heart from '../../assets/icon/red-hearttt.png'
import heartEmpty from '../../assets/icon/heart-22.png'
import pageEnum from '../../enums/pageEnum'
import { setOpenedPage } from '../../actions/urlService'
import { Link } from 'react-router-dom'
import ProductCardMobile from '../../Component/store/product/ProductCardMobile'
import ProductCard2 from '../../Component/store/product/ProductCard2'
import ProductCartSceleton from '../../Sceleton/ProductCartSceleton'
import ProductCartMobileSceleton from '../../Sceleton/ProductCartMobileSceleton'

const FavoritePage = () => {
  // const [favoriteProduct, setfavoriteProduct] = useState([]);
  const dispatch = useDispatch();
  const favoriteProduct = useSelector(state => state.favoriteProduct)

  const isLogedin = useSelector(state => state.isLogedin)

  const [isLoadingProduct, setIsLoadingProduct] = useState(true)

  useEffect(() => {
    setOpenedPage(pageEnum.FAVORITEPAGE, dispatch)
    window.scrollTo(0, 0)
    setIsLoadingProduct(true)
    getAllFavorite()
      .then(resolve => {
        // setProducts(resolve.data)
        dispatch({ type: reduxActionTypes.SETFAVORITEPRODUCT, value: resolve.data })
        // setfavoriteProduct(res.data)
        // console.log(res.data)
      },
        reject => {
          // console.log("dsdsd", reject)
        })
      .catch(error => console.error(error))
      .finally(() => setIsLoadingProduct(false))
  }, [])

  useEffect(() => { }, [favoriteProduct])


  return (
    <div className='favoritepage '>
      <StoreNavBar />
      {isLoadingProduct && <Container>
        <Row className="my-2 d-flex px-2" >
          {(new Array(10).fill(0)).map(() => {
            
            return <ProductCartSceleton />
          })}
          {(new Array(10).fill(0)).map(() => {
            
            return <ProductCartMobileSceleton />
          })}
        </Row>
      </Container>}
      {favoriteProduct.length !== 0 ? <>
        <div className='fav-header'>
          <span className='fav-image'><img src={heart} /> </span>
          <span className='fav-title'>Favorite</span>
        </div>
        <Container className='pt-4 pb-4'>
          <Row className="my-2 d-flex px-2" >
            {favoriteProduct.map(product => {
              if (product?.isLiked)
                return <>

                  <ProductCard2 key={product._id} product={product} />
                  <ProductCardMobile key={product._id+'m'} product={product} />
                </>
            }
            )}
          </Row>

        </Container>
      </> :
        <Container className='d-flex align-items-center flex-column pb-5 fav-header2'>
          <span className='image-fav'><img src={heartEmpty} /> </span>
          <h1 className='title-fav'>Your favorite product is empty</h1>
          <p className='text-fav'>What are you waiting for!</p>
          {isLogedin ?
            <Link className='button-fav' to="/storePage2">START SHOPPING</Link> :
            <Link className='button-fav' to="/login">Go to Login!</Link>
          }
        </Container>}
    </div>
  )
}

export default FavoritePage

