const orderStatus = {
    
    "PENDING": "PENDING",
    "SHIPPED": "SHIPPED",
    "DELIVERED": "DELIVERED",
    "RETURNED": "RETURNED",
    "OUT_OF_STOCK": "OUT OF STOCK",
    "CANCELLED": "CANCELLED",
    "PROCESSING": "PROCESSING",
    "COMPLETED": "COMPLETED",
    "ON_HOLD": "ON HOLD",
    "IN_TRANSIT": "IN TRANSIT",
    "REMOVE_FROM_USER": "REMOVE_FROM_USER"
    
}
export default orderStatus;