import React from 'react'
import './sevicespage.css'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import { Container, Row } from 'react-bootstrap'
import image from '../../assets/images/empty_image.png'
import { useState } from 'react'

const ServicesPage = () => {
    const [services, setServices] = useState(() => [{
        image: image,
        name: "Servie 1",
        description: "dhdhsbd jsdb sdjsds"
    }])

    return (
        <div className='sevicespage pb-5'>
            <StoreNavBar />
            <Container>
                {services.map(service => <ServiceCard service={service} />)}
            </Container>
        </div>
    )
}

export default ServicesPage

const ServiceCard = ({ service }) => {
    return (
        <div className='cartServices d-flex justify-content-start align-items-center mt-2'>
            <div><img src={service.image} className='imageServices' /></div>
            <div className='contentServices'>
                <h1 className='servicesTitle'>{service.name}</h1>
                <p className='servicesDesc'>{service.description}</p>
            </div>
        </div>
    )
}