import React, { useState } from 'react'
import "./contact.css"
import { addToContact } from '../../actions/contactAction'

const Contact = () => {
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [subject, setsubject] = useState("")
  const [message, setmessage] = useState("")

  const submitContact = () => {
    if(!isValide()) {
      alert("message not sent")
      return
    }
    addToContact(name, email, subject, message)
      .then(
        resolve => {
          console.log('resolve', resolve.success);
          alert("message sent");
          setemail("");
          setmessage("")
          setname("")
          setsubject("")
        }
      )
      .catch(
        error => {
          console.log('error', error)
        }
      )
  }

  const isValide = () => {
    let result = true
    if (email === "") {
      result = false
    }
    if (message === "") {
      result = false
    }
    if (name === "") {
      result = false
    }
    if (subject === "") {
      result = false
    }

    return result
  }

  return (


    <div>
      <section id="contact">
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-8 mx-auto text-center">
              {/* <h3 class="text-primary">CONTACT</h3> */}
              <h1>CONTACT</h1>
              <p>
                The contact section is where customers can find information on how to get in
                touch with the brand. You can also help to establish trustby a clear way for
                customers to communicate.

              </p>
            </div>
          </div>

          <div class="row g-3 justify-content-center form-contact">
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setname(e.target.value)}

              />
            </div>
            <div class="col-md-5">
              <input
                type="text"
                class="form-control"
                placeholder="Enter E-mail"
                value={email}
                onChange={(e) => setemail(e.target.value)}

              />
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Subject"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}

              />
            </div>
            <div class="col-md-10">
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                class="form-control"
                placeholder="Enter Message"
                value={message}
                onChange={(e) => setmessage(e.target.value)}

              ></textarea>
            </div>
            <div class="col-md-10 d-grid">
              <button className="btn  contactButton" onClick={submitContact} >
                Contact
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact
