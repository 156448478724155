import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import head from '../../assets/category/head.png';
import cartIcon_1 from '../../assets/icon/kcart.png';
import filterIcon_1 from '../../assets/icon/kfilter.png';
import heartIcon_1 from '../../assets/icon/kkfavo.png';
import store from '../../assets/icon/kstore.png';
import signInIcon_1 from '../../assets/icon/sign-in-1.png';
import logo_1 from "../../assets/logo/logo 1 without back.png";
import logo_2 from "../../assets/logo/logo 2 without back.png";
import './storeNavBar.css';
import { useEffect } from 'react';
import transaction from '../../assets/icon/transaction2.png';
import pageEnum from '../../enums/pageEnum';
import WebFilterBar from './filter/WebFilterBar';
import SearchBar from './searchbar/SearchBar';


const StoreNavBar = () => {
    // const [displayFilter, setdisplayFilter] = useState(false)

    const [isHovered, setIsHovered] = useState(() => false)
    // const dispatch = useDispatch()
    // const navigate = useNavigate()
    // const [showDropDown, setshowDropDown] = useState(false)
    const openedPage = useSelector(state => state.openedPage)
    const favoriteCount = useSelector(state => state.favoriteCount)
    const cartCount = useSelector(state => state.cartCount)

    const isLogedin = useSelector(state => state.isLogedin)
    const [isOpen, setisOpen] = useState(false)

    useEffect(() => {
        console.log('openedPage', openedPage)

    }, [openedPage])


    const toggleNavBar = () => {
        setisOpen(!isOpen)
    }
    const closeNavBar = () => {
        setisOpen(false)
    }


    return (

        <>
            <nav className="navbar navbar-expand-xl sticky-top" >
                < div className="container">
                    <Link className="logo navbar-brand fw-bold" to="/">
                        <img src={isHovered ? logo_2 : logo_1} className="img-fluid" alt="" onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => setIsHovered(false)} />
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation " onClick={toggleNavBar} >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav" >
                        <div className=''>
                            <SearchBar />
                        </div>

                        <ul className="navbar-nav ms-auto fw-semibold" >
                            <li className="nav-item ">
                                <Link className="nav-link pe-3 d-flex align-items-baseline" to="/FavoritePage">
                                    <div className='item-navBar'>
                                        <img src={heartIcon_1} className='signIn-icon' />
                                        <span className='count'>{favoriteCount}</span>
                                    </div> favorite
                                </Link>
                            </li>

                            <li className="nav-item ">
                                <Link className="nav-link pe-3 d-flex align-items-baseline" to="/TransactionPage">
                                    <div className='item-navBar'>
                                        <img src={transaction} className='signIn-icon' />

                                    </div> Transaction
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                            <Link className="nav-link pe-3" to="/">Home  <img src={filterIcon_1} className='signIn-icon' /></Link>
                        </li> */}

                            {/* {openedPage === pageEnum.STOREPAGE ?
                                <li className='d-none d-lg-block'>
                                    <a className="nav-link pe-3 " onClick={() => setdisplayFilter(pre => !pre)}>
                                        <img src={filterIcon_1} className='signIn-icon' /> Filter
                                    </a>
                                </li> : */}
                                <li className=''>
                                    <Link className="nav-link pe-3 " to="/StorePage2">
                                        <img src={store} className='signIn-icon' /> Store
                                    </Link>
                                </li>
                            {/* } */}

                            <li>
                                <Link className="nav-link pe-3 d-flex align-items-baseline" to="/CartPage">
                                    <div className='item-navBar'>
                                        <img src={cartIcon_1} className='signIn-icon' />
                                        <span className='count'>{cartCount}</span>
                                    </div> Cart
                                </Link>
                            </li>

                            <li className="nav-item">
                                {/* btn-primary */}
                                {/* onClick={() => logout(dispatch, navigate)} */}

                                {
                                    !isLogedin ?
                                        <Link to="/login" className="nav-link pe-3 ">
                                            <img src={signInIcon_1} className='signIn-icon' />
                                            <span>Sing in</span>
                                        </Link> :
                                        <div>
                                            <Link to="/profile">  <img
                                                src={head}
                                                className="account-logo d-none d-lg-block"
                                            // onClick={() => setshowDropDown(pre => !pre)}
                                            /> </Link>

                                            <Link to="/profile" className='nav-link d-block d-lg-none'>  <img
                                                src={head}
                                                className="signIn-icon"

                                            // onClick={() => setshowDropDown(pre => !pre)}
                                            /> Profile </Link>

                                        </div>}
                                {/* {showDropDown && <HomeDropDown />}
                                            <ul className="d-block d-md-none navbar-nav ms-auto fw-semibold">
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="#">Profile</a>
                                                </li> */}
                                {/* <li className="nav-item ">
            <a className="nav-link" href="#">Cart</a>
          </li> */}
                                {/* <li className="nav-item ">
                                                    <a className="nav-link" onClick={() => logout(dispatch, navigate)}>Logout</a>
                                                </li>
                                            </ul> */}



                            </li>
                        </ul>
                    </div>

                </div>
            </nav >
            {isOpen && (
                <div className=' navbar-overlay' onClick={closeNavBar}></div>
            )}
            {/* {openedPage === pageEnum.STOREPAGE && <>
                <div style={{
                    // position: "fixed",
                    // marginTop: displayFilter ? "75px" : "20px",
                    position: "fixed",
                    top: displayFilter ? "75px" : "20px",
                    transition: "all 0.5s ease",
                    zIndex: 2
                }} className='d-none d-lg-block'>
                    <WebFilterBar />
                </div>

                <div className='filterBar  d-lg-none' style={{
                    // position: "fixed",
                    // marginTop: displayFilter ? "75px" : "20px",
                    position: "fixed",
                    top: "0px",

                    zIndex: 2
                }}>
                    <WebFilterBar />
                </div>
            </>} */}
        </>



    );
};

export default StoreNavBar;