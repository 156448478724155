const filterCategory = {
    ELEVATOR: "ELEVATOR",
    PV : "PV",
    LASER_CUTTING: "LASER_CUTTING",
    MANUFACTURING: "MANUFACTURING",
    LATHEANDMILLING: "LATHE&MILLING",
    WOOD_ACCESSORIES: "WOOD_ACCESSORIES",
    ALL: "ALL"
}

export default filterCategory;