import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Slider from '../../Component/store/slider/Slider';
import DiscountSection from '../../Component/store/DiscountSection';
import HomeCategory from '../../Component/store/catergory/HomeCategory';
import CardProductsContainer from '../../Component/store/product/CardProductsContainer';
import { getSubCategories } from '../../actions/storeAction';
import { useState } from 'react';
import StoreNavBar from '../../Component/NavBar/StoreNavBar';

const StorePage1 = () => {
    const { category } = useParams();
    const [subCategories, setsubCategories] = useState([])

    useEffect(() => {
        getSubCategories(category)
            .then(resolve => setsubCategories(resolve))
            .catch(error => console.error(error))

        window.scrollTo(0, 0)
    }, [])


    return (<>
    <StoreNavBar/>
        <div style={{ marginTop: '76px' }}>
            <Slider />
            <DiscountSection text={"Sub category of " + category} />
            <HomeCategory subCategories={subCategories} />
            <DiscountSection text={"All product of " + category} />
            <CardProductsContainer />

            {/* {subCat.map(cat => {
                return <>
                    <h1 className=''>{cat.name}</h1>
                    <img src={cat.image} />
                    <p>{cat.descreption}</p>
                </>
            })} */}


        </div>
        </>
    )
}

export default StorePage1
