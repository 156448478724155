import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { like, unLike } from '../../../actions/storeAction'
import favoff from '../../../assets/icon/heart-22.png'
import favon from '../../../assets/icon/red-hearttt.png'
import rate from '../../../assets/icon/star.png'
import prod4 from '../../../assets/images/empty_image.png'
import pageEnum from '../../../enums/pageEnum'
import reduxActionTypes from '../../../enums/reduxActionTypes'
import './productcard2.css'

const ProductCard2 = ({ product }) => {

    const openedPage = useSelector(state => state.openedPage)
    // const user = useSelector(state => state.user)
    // const user = JSON.parse(Cookies.get("user"))
    const dispatch = useDispatch()
    const isLogedin = useSelector(state => state.isLogedin)

    const handleLike = () => {
        if (!isLogedin) {
            console.log('isLogedin', isLogedin)
            alert('You have to logIn')
            return
        }
        if (!product?.isLiked) {
            like(product._id)
                .then(
                    resolve => {
                        if (resolve.result) {
                            dispatch({ type: reduxActionTypes.SETLIKE, productId: product._id, value: true })
                        }
                    }
                )
                .catch(error => console.error(error))
        }
        else {
            unLike(product._id)
                .then(
                    resolve => {
                        if (resolve.result) {
                            if (openedPage === pageEnum.STOREPAGE) {
                                dispatch({ type: reduxActionTypes.SETLIKE, productId: product._id, value: false })
                                return
                            }
                            if (openedPage === pageEnum.FAVORITEPAGE) {
                                dispatch({ type: reduxActionTypes.REMOVEPRODUCTFROMFAVORITE, productId: product._id })
                                return
                            }

                        }
                    }
                )
                .catch(error => console.error(error))
        }
    }

    return (
        <Col sm="6" lg="4" xl="3" className="d-flex card-store2 justify-content-center px-5 px-sm-2 d-none d-sm-block">
            <div className="my-2 MainCard2" >
                <Link to={"/products/" + product?._id} style={{ cursor: "pointer" }}>
                    <div className='cardImage' >
                        <img src={product?.image[0]?.url ? product?.image[0]?.url : prod4} width={"100%"} alt={product?.key} />
                    </div>
                    <div className="card-title">
                        {product?.name}
                    </div>
                </Link>
                <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex justify-content-around ">
                        <div className=" ">
                            {product?.discount !== 0 && <div className='card-oldPrice'>
                                {product?.price[0]?.priceValue}$
                            </div>}
                            <div className='card-price'>
                                {product?.price[0]?.priceValue * (100 - product?.discount) / 100}$
                            </div>
                        </div>
                        {/* {product?.discount === 0 ?
                            <div className="card-price">{product?.price[0]?.priceValue}$</div>
                            :
                            <div className=' d-flex align-items-baseline'>
                                <div className=" mx-2 card-discount-price">
                                    <del> {product?.price[0]?.priceValue}$</del>
                                </div>
                                <div className="card-price">{product?.price[0]?.priceValue * (100 - product?.discount) / 100}$</div>
                            </div>} */}
                    </div>
                    <div className=' d-flex flex-column justify-content-end align-items-center' style={{ height: " 3em" }}>
                        {product?.discount !== 0 && <div className=' card-discount my-0'>{product?.discount !== 0 ? `${product?.discount}% Discount` : ""}</div>}
                        <div className=' d-flex justify-content-end '>
                            <div className="d-flex ">
                                <img
                                    className=""
                                    src={rate}
                                    alt=""
                                    height="20px"
                                    width="20px"
                                    style={{ marginTop: '2px' }}

                                />
                                <div className="card-rate mx-2">{product?.rating.rate}</div>
                            </div>
                            {/* <div>
                        <Link to={"/products/" + product?._id} style={{ textDecoration: 'none' }}>
                            <button className='card-buttom'>Read More</button>
                        </Link>
                    </div> */}

                            <div className=" ms-2" onClick={handleLike}>
                                <img
                                    src={product?.isLiked ? favon : favoff}
                                    alt=""
                                    className="text-center"
                                    style={{
                                        height: "24px",
                                        width: "24px",
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default ProductCard2