import React, { useState } from 'react'
import './checkoutbody.css'
import adress from '../../assets/icon/locationIcon.png'
import truck from '../../assets/icon/truck2.png'
import commentIcon from '../../assets/icon/comment.png'
import { Row } from 'react-bootstrap'
import 'react-phone-number-input/style.css'

const CheckOutBody = (
    { 
        setDelivery, 
        delivery,
        firstName,
        setfirstName,
        lastName,
        setlastName,
        city,
        setcity,
        location,
        setlocation,
        phone,
        setphone,
        comment,
        setcomment,
        msgcity,
        msgfirstName,
        msglastName,
        msglocation,
        msgphone
     }
    ) => {
    // const [phoneNumber, setphoneNumber] = useState('')
    const option = delivery === true ? { checked: true } : {}
    return (
        <>
            <div className='checkoutbody container pb-4'>
                <div className='row titleSection d-flex  justify-content-center'>
                    <div className='col-12 '><img src={adress} className='locationIcon' /> Address</div>
                </div>
                <Row className='bodySection pb-3'>
                    <div className='col-6 d-flex flex-column'>
                        <div className='labelOrder'>First name <label className="error-message">{msgfirstName}</label></div>
                        <div ><input type='text' className='inputOrder' value={firstName} onChange={(e)=>setfirstName(e.target.value)}/></div>
                        <div className='labelOrder'>Last Name <label className="error-message">{msglastName}</label></div>
                        <div><input type='text' className='inputOrder' value={lastName} onChange={(e)=>setlastName(e.target.value)}/></div>
                        <div className='labelOrder'>Telephone <label className="error-message">{msgphone}</label></div>
                        <div>
                            {/* <input type='text' className='inputOrder' /> */}
                            {/* <ReactPhoneInput
                                defaultCountry='LB'
                                // onCountryChange={() => {
                                //     const code = getCountryCallingCode('LB')
                                //     console.log('code', code)
                                // }
                                // }
                                value={phoneNumber}
                                onChange={setphoneNumber}
                                // className='inputOrder'
                                inputComponent={({ value, onChange }) => <input type='tel' className='inputOrder' value={value} onChange={e=>onChange(e.target.value)}/>}
                            /> */}
                            <input type='tel' className='inputOrder' value={phone} onChange={e=>setphone(e.target.value)}/>
                        </div>
                    </div>

                    <div className='col-6 d-flex flex-column'>
                        <div className='labelOrder'>City <label className="error-message">{msgcity}</label></div>
                        <div ><input type='text' className='inputOrder' value={city} onChange={(e)=>setcity(e.target.value)}/></div>
                        <div className='labelOrder'>Location <label className="error-message">{msglocation}</label></div>
                        <div><input type='text' className='inputOrder' value={location} onChange={(e)=>setlocation(e.target.value)}/></div>
                    </div>
                </Row>
            </div>

            <div className='checkoutbody container pb-4'>
                <div className='row titleSection'>
                    <div className='col-12 '><img src={truck} className='locationIcon' /> Shipping Method</div>
                </div>

{/* Row delvery takeaway */}
                <Row className='bodySection pb-3'>
                    <div className='col-6 d-flex flex-column'>
                        <div className='labelOrder'>
                            <input type='radio' name='shipping' id='delivery' onClick={() => setDelivery(true)} {...option} /><label htmlFor='delivery'>Delivery</label> </div>
                    </div>

                    <div className='col-6 d-flex flex-column'>
                        <div className='labelOrder'>

                            <input type='radio' name='shipping' id="takeaway" onClick={() => setDelivery(false)} /> 
                            <label htmlFor="takeaway">Takeaway</label><br></br>
                            <label htmlFor="takeaway">view location</label>
                            </div>
                    </div>
                </Row>


            </div>

            <div className='checkoutbody container pb-4'>
                <div className='row titleSection d-flex  justify-content-center'>
                    <div className='col-12 '><img src={commentIcon} className='locationIcon' /> Comment</div>
                </div>
                <Row className='bodySection pb-3'>
                    <div className='col-12 d-flex flex-column'>

                        <div ><textarea type='text' className='inputOrder' rows="3" onChange={(e)=>setcomment(e.target.value)}>{comment}</textarea></div>

                    </div>
                </Row>
            </div>

        </>
    )
}

export default CheckOutBody

