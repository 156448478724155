import React from 'react'
import './bill.css'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCartItems } from '../../actions/storeAction';
import reduxActionTypes from '../../enums/reduxActionTypes';
import { useState } from 'react';
const Bill = ({setinCheckOut}) => {

  const cartProduct = useSelector(state => state.cartProduct);
  const [price,setPrice] = useState(0)
  

  
  useEffect(()=>{
   
    const value = cartProduct.reduce((sum , pro) => {return sum + pro.productPrice*(100-pro.productDiscount)/100 *pro.quantity}, 0)
    setPrice(value)
  },[cartProduct])

  console.log(price)
  return (
    <Container className='bill'>
      <h2 className='title-summary'>Order Summary </h2>
      <h5 className='count-summary'>{cartProduct?.length} items</h5>
      <div className='d-flex justify-content-between'>
        <div className='key-bill'>sub-total</div>
        <div className='value-bill'>{price}$</div>
      </div>
      <div className='d-flex justify-content-between bill-totale-container'>
        <div className='key-bill'>total</div>
        <div className='value-bill'>{price}$</div>
      </div>
      <div className='d-flex justify-content-center'>
        <button className='bill-button' onClick={()=>setinCheckOut(true)}  >CHECKOUT NOW</button>
      </div>
    </Container>
  )
}

export default Bill
