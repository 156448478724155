import React, { useRef } from 'react'
import './productSlider2.css'
import { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ProductCard2 from '../../store/product/ProductCard2';
import { useState } from 'react';
import { getNProducts } from '../../../actions/slideActions';
import { useDispatch, useSelector } from 'react-redux';
import { like, unLike } from '../../../actions/storeAction';
import reduxActionTypes from '../../../enums/reduxActionTypes';
import pageEnum from '../../../enums/pageEnum';
import favoff from '../../../assets/icon/heart-22.png'
import favon from '../../../assets/icon/red-hearttt.png'
import rate from '../../../assets/icon/star.png'
import prod4 from '../../../assets/images/empty_image.png'
import { Link } from 'react-router-dom';
import ProductCartSceleton from '../../../Sceleton/ProductCartSceleton';

const ProductSlider2 = ({ category }) => {
    const [items, setItems] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isLoadingProduct, setIsLoadingProduct] = useState(true)
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsLoadingProduct(true)
        getNProducts('ALL', 1, 8, category?._id, '', '')
            .then(
                resolve => {
                    setItems(resolve)
                },
                reject => console.log('error:', reject?.msg)
            )
            .catch(error => console.error(error))
            .finally(()=>setIsLoadingProduct(false))
    }, [])


    const slideNb = Math.min(Math.ceil(windowWidth / 220), 5)

    const settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: slideNb,
        slidesToScroll: 1
    };

    if(items.length < 5) return <></>

    return (
    <div className='productSlider2 mt-3'>

        <div className=' sliderBody'>
            <div className=" d-flex justify-content-between align-items-start">
                <h3 className="title-section ">{category?.name}</h3>
                <Link to={`/StorePage2?filterCategory=${encodeURIComponent(category?._id)}&filterSubCategory=ALL&searchQuery=`}
                    className=' sliderBtn'>
                   <span>VIEW ALL</span>
                </Link>
            </div>
            <Slider {...settings} autoplay autoplaySpeed={3000} className='pSlider'>
                {items.map(item => <ProductCard key={Math.random()} product={item} setProducts={setItems} />)}
            </Slider>
        </div>
    </div>
    )
}

export default ProductSlider2

const ProductCard = ({ product, setProducts }) => {

    // const openedPage = useSelector(state => state.openedPage)
    // const dispatch = useDispatch()
    const isLogedin = useSelector(state => state.isLogedin)

    const handleLike = () => {
        if (!isLogedin) {
            console.log('isLogedin', isLogedin)
            alert('You have to logIn')
            return
        }
        if (!product?.isLiked) {
            like(product._id)
                .then(
                    resolve => {
                        if (resolve.result) {
                            setProducts(pre => pre.map(p => {
                                if (p?._id === product?._id) return { ...p, isLiked: true }
                                return p
                            }))
                            // dispatch({ type: reduxActionTypes.SETLIKE, productId: product._id, value: true })
                        }
                    }
                )
                .catch(error => console.error(error))
        }
        else {
            unLike(product._id)
                .then(
                    resolve => {
                        if (resolve.result) {
                            setProducts(pre => pre.map(p => {
                                if (p?._id === product?._id) return { ...p, isLiked: false }
                                return p
                            }))
                        }
                    }
                )
                .catch(error => console.error(error))
        }
    }

    return (
        <div className="card-store3 text-center">
            <div className="my-2 MainCard3" >
                <Link to={"/products/" + product?._id} style={{ cursor: "pointer" }}>
                    <div className='cardImage' >
                        <img src={product?.image[0]?.url ? product?.image[0]?.url : prod4} width={"100%"} alt={product?.key} />
                    </div>
                    <div className="card-title">
                        {product?.name}
                    </div>
                </Link>
                <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex justify-content-around ">
                    <div className="">
                            {product?.discount !== 0 && <div className='card-oldPrice'>
                                {product?.price[0]?.priceValue}$
                            </div>}
                            <div className='card-price'>
                                {product?.price[0]?.priceValue * (100 - product?.discount) / 100}$
                            </div>
                        </div>
                    </div>
                    <div className=' d-flex flex-column justify-content-end align-items-center' style={{ height: " 3em" }}>
                        {product?.discount !== 0 && <div className=' card-discount my-0'>{product?.discount !== 0 ? `${product?.discount}% Discount` : ""}</div>}
                        <div className=' d-flex justify-content-end align-items-center'>
                            <div className="d-flex pt-1">
                                <img
                                    className=""
                                    src={rate}
                                    alt=""
                                    height="16px"
                                    width="16px"
                                    style={{ marginTop: '1px' }}

                                />
                                <div className="card-rate mx-2">{product?.rating.rate}</div>
                            </div>

                            <div className=" " onClick={handleLike}>
                                <img
                                    src={product?.isLiked ? favon : favoff}
                                    alt=""
                                    className="text-center"
                                    style={{
                                        height: "18px",
                                        width: "18px",
                                        cursor: "pointer"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <ProductCartSceleton/>
        
    )
}