import React from 'react'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'
import { Col } from 'react-bootstrap'

const ProductCartSceleton = () => {
    const themeClass= 'light'

    return (
    //   <div className={`parent-category ${themeClass} skeleton-category`}>
    //         <Shimmer />
    //   </div>
    <Col sm="6" lg="4" xl="3" className="d-flex  justify-content-center px-5 px-sm-2 d-none d-sm-block "
    style={{
        minWidth: "calc(100% / 5)",
        width:" 220px"
    }}
    >
    <div className="my-2 parent-productweb" >
            <div className=' d-flex justify-content-center' >
            <SkeletonElement type="avatar" />
            </div>
            <div className="card-title pt-3">
            <SkeletonElement type="title" />
            </div>
         <Shimmer />
    </div>  
 
</Col>

    )
}

export default ProductCartSceleton