import React from 'react'
import productImage from '../../assets/images/empty_image.png'
import './cartitem.css'
import deleteIcon from '../../assets/icon/delete.png'
import { removeFromCart } from '../../actions/storeAction'
import { useDispatch } from 'react-redux'
import reduxActionTypes from '../../enums/reduxActionTypes'

const CartItem = ({ item }) => {
  // const item = {
  //   productId: "sdhhwwy23",
  //   productName: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident, nobis!",
  //   productImage: productImage,
  //   productCode: "436323552",
  //   productPrice: 23,
  //   productDiscount: 15,
  //   color: "#f00",
  //   size: "100*50",
  //   material: "plastic",
  //   quantity: 5,
  //   _id: "erhfbh3y44"
  // }
  const dispatch = useDispatch();
  const deletFromCart = () => {
    removeFromCart(item?._id)
      .then(resolve => {

        console.log("remove:", resolve)
        dispatch({ type: reduxActionTypes.REMOVEPRODUCTFROMCART, cartId: item._id })
      })
    // console.log(item?._id)
  }


  return (
    <div className="container pb-1">
      <div className='cartItem row p-2 mb-3' style={{ borderColor: 'var(--colorText)' }}>
        <div className='col-sm-3 d-none d-sm-flex align-items-center' style={{ borderRight: '2px solid ' + item.color }}>
          <img src={item?.productImage ? item?.productImage : productImage} alt="productImage" style={{ width: "100%" }} />
        </div>
        <div className='col-12 col-sm-9 cartDetails'>
          <div className='cartTitle pt-1'>
            {item?.productName}
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div className=' flex-grow-1'><span className='cartInfoKey'>ID: </span><span>{item?.productKey}</span></div>
            <div className='cartPrice'>
              {item?.productDiscount === 0 ?
                <div>
                  <span className='cartPriceNew'>{item?.productPrice}$
                  </span>
                </div> :
                <div>
                  {/* <span className='cartInfoKey'>price: </span> */}
                  <del className='cartPriceOld'> {item?.productPrice}$ </del>

                  <span className='cartPriceNew'>{item?.productPrice * (100 - item?.productDiscount) / 100}$
                  </span>
                  <span className='cartPriceDiscount' >(-{item?.productDiscount}%)</span>
                </div>}
            </div>

          </div>
          <div className="d-flex justify-content-between pt-2">
            <div className=' d-flex align-items-center'>
              {/* <span className='cartInfoKey'>color: </span> */}
              <span className='cartColor' style={{ backgroundColor: item?.color }}></span></div>
            <div>
              {/* <span className='cartInfoKey'>size: </span> */}
              <span>{item?.size} </span>
              
              </div>
            <div>
              {/* <span className='cartInfoKey'>material: </span> */}
              <span>{item?.material}</span>
              </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div><button className='cartRemoveBtn' onClick={deletFromCart}>Remove<img src={deleteIcon} /></button></div>
            <div>
              {/* <input type="number" value={item.quantity} /> */}
              <span className='cart-quantity'>{item.quantity} item</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
