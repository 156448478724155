import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import Bill from '../../Component/cart/Bill'
import BillOrder from '../../Component/cart/BillOrder'
import CartItem from '../../Component/cart/CartItem'
import CartItemMobile from '../../Component/cart/CartItemMobile'
import CheckOutBody from '../../Component/cart/CheckOutBody'
import { addToTransaction, getAllCartItems } from '../../actions/storeAction'
import { setOpenedPage } from '../../actions/urlService'
import cartImage from '../../assets/icon/kcart.png'
import pageEnum from '../../enums/pageEnum'
import reduxActionTypes from '../../enums/reduxActionTypes'
import './cartpage.css'
const CartPage = () => {
  const dispatch = useDispatch();
  const cartProduct = useSelector(state => state.cartProduct);

  // const [inCheckOut, setinCheckOut] = useState('false')
  const [searchParms, setSearchParms] = useSearchParams({ inCheckOut: "false" })

  const [delivery, setDelivery] = useState(true)
  const [firstName, setfirstName] = useState(() => '')
  const [msgfirstName, setmsgfirstName] = useState(() => '')
  const [msglastName, setmsglastName] = useState(() => '')
  const [lastName, setlastName] = useState(() => '')
  const [phone, setphone] = useState(() => '')
  const [msgphone, setmsgphone] = useState(() => '')
  const [city, setcity] = useState(() => '')
  const [msgcity, setmsgcity] = useState(() => '')
  const [location, setlocation] = useState(() => '')
  const [msglocation, setmsglocation] = useState(() => '')
  const [comment, setcomment] = useState(() => '')
  const isLogedin = useSelector(state => state.isLogedin)
  const navigate = useNavigate()

  const initValidetor = () =>{
    setmsgfirstName("");
    setmsglastName("");
    setmsglocation("");
    setmsgphone("");
    setmsgcity("");
}

const isValide = () => {
  let b = true;
  initValidetor();
  
  if(!/^[A-Za-z]+$/.test(firstName)){
      setmsgfirstName("using only character");
      b = false;
  }
  if(firstName===""){
      setmsgfirstName("first name is empty");
      b = false;
  }
 
  if(!/^[A-Za-z]+$/.test(lastName)){
      setmsglastName("using only character");
      b = false;
  }
  if(lastName===""){
      setmsglastName("last name is empty");
      b = false;
  }
 
  if(!/^\d{8,}$/.test(phone)){
    setmsgphone("phon number invalid");
    b = false;
}
if(phone===""){
    setmsgphone("phon is empty");
    b = false;
}

if(!/^(?=.{3,})/.test(location)){
  setmsglocation("location lenght<3");
  b = false;
}
if(location===""){
  setmsglocation("location is empty");
  b = false;
}

if(!/^(?=.{3,})/.test(city)){
  setmsgcity("city lenght<3");
  b = false;
}
if(city===""){
  setmsgcity("city is empty");
  b = false;
}
return b;
}

  const inCheckOut = searchParms.get("inCheckOut") === "true"
  const setinCheckOut = (val) => {
    setSearchParms(prev => {
      return { ...prev, inCheckOut: val }
    })
  }

  // const {inCheckOut} = useParams()
  // const setinCheckOut = () => {}

  // useEffect(()=>{console.log(delivery)},[delivery])


  useEffect(() => {
    console.log('inCheckOut', inCheckOut)
    setOpenedPage(pageEnum.CARTPAGE, dispatch)
    getAllCartItems()
      .then((resolve) => {
        dispatch({ type: reduxActionTypes.SETCARTPRODUCT, value: resolve.data })
        console.log("data:", resolve.data)
      },
      reject => {
        console.log('reject.msg', reject?.msg)
      })

      .catch((error) => {
        console.error(error);
      })
      window.scrollTo(0, 0);
  }, [])
  useEffect(() => { console.log(inCheckOut) }, [inCheckOut])

  const checkOutNow = () => {
    if (!isValide())
    return
    const clientName = firstName + " " + lastName
    const items = cartProduct.map(item => {
      return {
        ...item,
        _id: undefined,
        // productId: undefined,
      }
    })
    console.log('cartProduct', cartProduct)
    addToTransaction(items, comment, clientName, city, location, phone, delivery)
      .then(resolve => {
        console.log('resolve', resolve) 
        dispatch({ type: reduxActionTypes.SETCARTCOUNT, value: 0 })
        navigate('/TransactionPage')
      })
      .catch(error => console.error(error))
  }


  return (

    <div className='cartpage '>
      <StoreNavBar />
      {cartProduct.length !== 0 ?

        <Container >
          <Row className='mb-2'>
            <Col>
              <img src={cartImage} className='cart-image' />
              <span className='text-title'> {inCheckOut === true ? 'Checkout Now' : 'My Cart(' + cartProduct?.length + ')'}     </span>
            </Col>
          </Row>

          <Row>
            <div className='col-12 col-lg-8 pb-3'>
              {/* {cartProduct.map(pro=><CartItem item={ {...pro , productImage : productk }}/>)}  */}
              {inCheckOut === true ?
                <CheckOutBody
                  setDelivery={setDelivery}
                  delivery={delivery}
                  firstName={firstName}
                  setfirstName={setfirstName}
                  lastName={lastName}
                  setlastName={setlastName}
                  city={city}
                  setcity={setcity}
                  location={location}
                  setlocation={setlocation}
                  phone={phone}
                  setphone={setphone}
                  comment={comment}
                  setcomment={setcomment}
                  msgcity={msgcity}
                  msgfirstName={msgfirstName}
                  msglastName={msglastName}
                  msglocation={msglocation}
                  msgphone={msgphone}
                />
                :
                <div> {cartProduct?.map(pro =>
                  <>
                    <div className='d-block d-sm-none'>
                      <CartItemMobile item={pro} />
                    </div>
                    <div className='d-none d-sm-block'>
                      <CartItem item={pro} />
                    </div>
                  </>
                )}</div>}
              {/* <CartItemMobile/> */}
            </div>

            {inCheckOut === true ?
              <div className='col-12 col-lg-4 '><BillOrder delivery={delivery} checkOutNow={checkOutNow} /></div>
              :
              <div className='col-12 col-lg-4 '> <Bill setinCheckOut={setinCheckOut} /></div>}

          </Row>
        </Container>
        :
        <Container className='d-flex align-items-center flex-column pb-5 fav-header2'>
          <span className='image-fav'><img src={cartImage} /> </span>
          <h1 className='title-fav'>Your cart is empty</h1>
          <p className='text-fav'>What are you waiting for!</p>
          {isLogedin ?
            <Link className='button-fav' to="/storePage2">START SHOPPING</Link> :
            <Link className='button-fav' to="/login">Go to Login!</Link>
          }
        </Container>
      }
    </div>

  )
}

export default CartPage
