
import filterCategory from '../enums/filterCategory'
import pageEnum from '../enums/pageEnum'
import reduxActionTypes from '../enums/reduxActionTypes'
import sortBy from '../enums/sortBy'
import img_7 from '../assets/category/bag_1.png'

const initState = {
    user: { email: '' },
    isLogedin: false,
    products: [],
    searchQuery: '',
    sortBy: sortBy.PRICEASSEND,
    filterCategory: filterCategory.ALL,
    filterSubCategory: 'ALL',
    productsCount: 0,
    productsName: [],
    openedPage: pageEnum.HOMEPAGE,
    favoriteProduct: [],
    cartProduct: [],
    favoriteCount: 0,
    cartCount: 0,
    userTransaction: [],
    slider: [],
    mainCategories: [],
    subCategories: []
}

const rootReducer = (state = initState, action) => {
    switch (action.type) {
        case reduxActionTypes.SETUSER:
            state = { ...state, user: action.user }
            break
        case reduxActionTypes.UPDATEUSER:
            state = { ...state, user: { ...state.user, ...action.data } }
            break
        case reduxActionTypes.LOGIN:
            state = { ...state, isLogedin: true }
            break
        case reduxActionTypes.LOGOUT:
            // state = { ...state, isLogedin: false, user: { email: '' } }
            state = initState
            break
        case reduxActionTypes.SETPRODUCTS:

            // state = { ...state, products:[] }
            state = { ...state, products: action.products }
            break
        case reduxActionTypes.SETLIKE:
            state = {
                ...state, products: state.products.map(p => {
                    if (p._id === action.productId) {
                        p.isLiked = action.value
                    }

                    return p
                }),
                favoriteCount: action.value ? state.favoriteCount + 1 : state.favoriteCount - 1,

            }

            break
        // case reduxActionTypes.SETSEARCHQUERY:
        //     state = { ...state, searchQuery: action.searchQuery }
        //     break
        // case reduxActionTypes.SETFILTERCATEGORY:
        //     state = { ...state, filterCategory: action.filterCategory }
        //     break
        // case reduxActionTypes.SETSORTBY:
        //     state = { ...state, sortBy: action.sortBy }
        //     break
        // case reduxActionTypes.SETFILTERSUBCATEGORY:
        //     state = { ...state, filterSubCategory: action.filterSubCategory }
        //     break
        case reduxActionTypes.SETPRODUCTSCOUNT:
            state = { ...state, productsCount: action.count }
            break
        case reduxActionTypes.SETPRODUCTSNAME:
            state = { ...state, productsName: action.value }
            break
        case reduxActionTypes.SETOPENEDPAGE:
            state = { ...state, openedPage: action.value }
            break
        case reduxActionTypes.SETFAVORITEPRODUCT:
            state = { ...state, favoriteProduct: action.value }
            break
        case reduxActionTypes.REMOVEPRODUCTFROMFAVORITE:
            const newFav = state.favoriteProduct.filter(p => p._id !== action.productId)
            state = { ...state, favoriteProduct: newFav, favoriteCount: state.favoriteCount - 1 }
            break
        case reduxActionTypes.SETCARTPRODUCT:
            state = { ...state, cartProduct: action.value }
            break
        case reduxActionTypes.REMOVEPRODUCTFROMCART:
            const newItem = state.cartProduct.filter(p => p._id !== action.cartId)
            state = { ...state, cartProduct: newItem, cartCount: state.cartCount - 1 }
            break

        case reduxActionTypes.SETFAVORITECOUNT:
            state = { ...state, favoriteCount: action.value }
            break
        case reduxActionTypes.SETSLIDER:
            state = { ...state, slider: action.value }
            break
        // case reduxActionTypes.INCFAVORITECOUNT:
        //     state = { ...state, favoriteCount: state.favoriteCount + 1 }
        //     break
        // case reduxActionTypes.DECFAVORITECOUNT:
        //     state = { ...state, favoriteCount: state.favoriteCount - 1 }
        //     break

        case reduxActionTypes.SETCARTCOUNT:
            state = { ...state, cartCount: action.value }
            break
        case reduxActionTypes.INCCARTCOUNT:
            state = { ...state, cartCount: state.cartCount + 1 }
            break
        case reduxActionTypes.SETUSERTRANSACTION:
            state = { ...state, userTransaction: action.value }
            break
        // case reduxActionTypes.SETMAINCATEGORIES:
        //     state = { ...state, mainCategories: action.value }
        //     break
        case reduxActionTypes.SETCATEGORIES:
            state = {
                ...state,
                mainCategories: [{
                    _id: "ALL",
                    image: { url: img_7 },
                    name: "All",
                    parent: 'ROOT',
                    descreption: ""
                }, ...action?.value?.filter(cat => cat?.parent === 'ROOT')],
                subCategories: action?.value?.filter(cat => cat?.parent !== 'ROOT')
            }
            break


    }
    return state
}

export default rootReducer