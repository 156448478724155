import React, { useEffect, useState } from 'react';
// import 'animate.css';
import { Link } from 'react-router-dom';
import SignUp from './SignUp';
import LogIn from './LogIn';
import backgroundImage from '../../assets/images/c2.jpg';
import Verification from './Verification';
import GuestNavbar from '../../Component/NavBar/GuestNavBar';
// import backgroundImage2 from './../../assets/images/background sign&log/c2.jpeg';


const SignUpPage = () => {
    const [newUser, setnewUser] = useState(false)
    const [verify, setVerify] = useState(false)

    const handleSwitchMethod = () => {
        setnewUser(preState => !preState)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [verify])

    return (<>
    <GuestNavbar/>
        <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', color: "white" ,backgroundPosition: "center"}}>
            <div className="container py-5 mt-5">

                <button className={`btn btn-primary `}
                    style={{
                        border: '1px primary blue',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        display: verify ? 'none' : 'block'
                    }}
                    onClick={handleSwitchMethod}

                >
                    {
                        newUser ?
                            'I have an account' :
                            "Don't have an account"
                    }
                </button>


                <div className="row justify-content-center signup-page">
                    <div className="col-md-4">
                        <div className="text-center mb-4">

                        </div>
                        <form className={`user-form ${true ? 'active' : ''}`} style={{ color: "white", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}>
                            {
                                verify ?
                                    <Verification /> :
                                    newUser ?
                                        <SignUp setVerify={setVerify} /> :
                                        <LogIn />
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default SignUpPage;
