import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import UnopDropdown from 'unop-react-dropdown'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import ProductCard2 from '../../Component/store/product/ProductCard2'
import ProductCardMobile from '../../Component/store/product/ProductCardMobile'
import Slider from '../../Component/store/slider/Slider'
import ProductCartMobileSceleton from '../../Sceleton/ProductCartMobileSceleton'
import ProductCartSceleton from '../../Sceleton/ProductCartSceleton'
import { getAllSlider, getNProducts, getProductsNumber } from '../../actions/storeAction'
import { setOpenedPage } from '../../actions/urlService'
import catimage from '../../assets/icon/catArrow.png'
import filterCategory from '../../enums/filterCategory'
import pageEnum from '../../enums/pageEnum'
import reduxActionTypes from '../../enums/reduxActionTypes'
import { default as sortByEnum, default as sortByFilter } from '../../enums/sortBy'
import './categoryList.css'
import './store.css'
import './subCategoryList.css'

const StorePage2 = () => {

  const dispatch = useDispatch();
  const products = useSelector(state => state.products)
  const productsCount = useSelector(state => state.productsCount)
  // const productsCount = useSelector((state) => state.setProductsCount)

  ///
  const [searchParms, setSearchParms] = useSearchParams()
  // const subCat = useSelector(state => state.filterSubCategory)
  const subCat = searchParms.get("filterSubCategory") || "ALL"
  // const mainCat = useSelector(state => state.filterCategory)
  const mainCat = searchParms.get("filterCategory") || filterCategory.ALL
  // const sortBy = useSelector(state => state.sortBy)
  const sortBy = searchParms.get("sortBy") || sortByFilter.NEWPRODUCT
  // const searchQuery = useSelector(state => state.searchQuery);
  const searchQuery = searchParms.get("searchQuery") || ""
  ///
  // const currentPage = searchParms.get("currentPage") || 1
  ///

  // const [mainCat, setmainCat] = useState("PV")
  const itemsPerPage = 10;
  // const [itemOffset, setItemOffset] = useState(() => 0);
  // const endOffset = itemOffset + itemsPerPage;
  // const currentItems = pro.slice(itemOffset, endOffset);
  const [pageCount, setPageCount] = useState(() => 0)
  const [currentPage, setCurrentPage] = useState(() => 1)
  const [isLoadingProduct, setIsLoadingProduct] = useState(true)

  const setSortBy = (value) => {
    setSearchParms(prev => {
      prev.set("sortBy", value)
      return prev
    }, { replace: true })
  }

  // const setCurrentPage = (value) => {
  //   setSearchParms(prev => {
  //     prev.set("currentPage", value)
  //     return prev
  //   }, { replace: true })
  // }

  const setProductsCount = (value) => {
    dispatch({ type: reduxActionTypes.SETPRODUCTSCOUNT, count: value })
  }
  // const setSubCat = (value) => {
  //   dispatch({type: reduxActionTypes.SETFILTERSUBCATEGORY, filterSubCategory: value})
  // }
  // const setMainCat = (value) => {
  //   dispatch({type: reduxActionTypes.SETFILTERCATEGORY, filterCategory: value})
  // }

  useEffect(() => {
    setOpenedPage(pageEnum.STOREPAGE, dispatch)

    getAllSlider().then(
      resolve => {
        dispatch({ type: reduxActionTypes.SETSLIDER, value: resolve.data })
        // console.log('Slider:', resolve.data)
      },
      reject => {
        console.log(reject.msg)
      }
    ).catch(error => console.error(error))
    ///
    setSearchParms(prev => {
      if (prev.get("filterCategory") === null) {
        prev.set("filterCategory", "ALL")
      }
      return prev
    }, { replace: true })

  }, [])


  useEffect(() => {
    // console.log("from shopProductsPage: " + user.email)
    window.scrollTo(0, 0);
    getProductsNumber(subCat, mainCat, searchQuery)
      .then((res) => {
        setProductsCount(res.count)
        setCurrentPage(1)
        setPageCount(Math.ceil(res.count / itemsPerPage))
      })
      .catch(error => console.error(error))

    setIsLoadingProduct(true)
    getNProducts(subCat, 1, itemsPerPage, mainCat, sortBy, searchQuery)
      .then(res => {
        // console.log('products:', res.data)
        dispatch({ type: reduxActionTypes.SETPRODUCTS, products: res.data })
        // console.log(res.data)

      })
      .catch(error => console.error(error))
      .finally(() => setIsLoadingProduct(false))
  }, [mainCat, subCat, sortBy, searchQuery]);

  useEffect(() => {
    setIsLoadingProduct(true)
    getNProducts(subCat, currentPage, itemsPerPage, mainCat, sortBy, searchQuery)
      .then(res => {
        // setProducts(res.data)
        dispatch({ type: reduxActionTypes.SETPRODUCTS, products: res.data })
        // console.log(res.data)

      })
      .catch(error => console.error(error))
      .finally(() => setIsLoadingProduct(false))

    return () => {

    }
  }, [currentPage])

  const handlePageClick = (event) => {
    // console.log(event.selected)
    setCurrentPage(event.selected + 1)
  };

  return (
    <div className='StorePage2'>
      <div>
        <StoreNavBar />
        <Slider />

        <CategoryList />
        <Container>
          <div className=' d-flex align-items-center justify-content-between w-100 subCatWithSort'>
            <div style={{ width: "fit-content", paddingInline: "10px" }} className='title-filter d-flex'>
              <span className=' d-none d-sm-block pe-1'>Results:</span>
              <span >{productsCount}</span>
            </div>
            <div className=" d-flex ">
              <span className='title-filter pe-1'>Sort By:</span>
              <select className=' sortBy ' aria-label="Default select example"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option className="border-bottom card-filter-item" value={sortByEnum.PRICEASSEND}>
                  Price: Low to High
                </option>
                <option className="border-bottom card-filter-item" value={sortByEnum.PRICEDESCEND}>
                  Price: High to Low
                </option>
                <option className="border-bottom card-filter-item" value={sortByEnum.DISCOUNTPRODUCT}>
                  Discount Products
                </option> <option className="border-bottom card-filter-item" value={sortByEnum.NEWPRODUCT}>
                  New Products
                </option>
              </select>
            </div>



          </div>
        </Container>
        {/* <CategoryTitle /> */}
      </div>
      {isLoadingProduct && <Container>
        <Row className="my-2 d-flex px-2" >
          {(new Array(10).fill(0)).map(() => {

            return <ProductCartSceleton />
          })}
          {(new Array(10).fill(0)).map(() => {

            return <ProductCartMobileSceleton />
          })}
        </Row>
      </Container>}
      {products.length !== 0 ?
        <Container>
          {/* <SearchCountResult title={`${products.length}`} setSearch={setSearchVal} filterByTitle={filterByTitle} /> */}
          {/* <Row className="d-flex flex-row " > */}
          {/* justify-content-between */}
          <Row className="my-2 d-flex px-2" >

            {products.map((product) => (
              <>
                <ProductCard2 key={product._id} product={product} />
                {/* <ProductCartSceleton /> */}
                <ProductCardMobile key={product._id + 'm'} product={product} />
              </>
            ))}
          </Row>
          {/* </Row> */}

          {/* {pageCount > 0 && */}
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="prev"
              containerClassName={"pagination justify-content-center p-3"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          </div>
        </Container> :
        <Container className='d-flex align-items-center flex-column pb-5 fav-header2'>
          <h1 className='no-result'>No results found!</h1>
        </Container>
      }
    </div>
  )


}

const CategoryList = () => {
  const mainCategories = useSelector(state => state.mainCategories)
  const subCategories = useSelector(state => state.subCategories)
  // const colors = ["red", "purple", "green", "orange", "blue", "black"]
  const [searchParms, setSearchParms] = useSearchParams()
  // const [subCat, setsubCat] = useState([])

  const mainCat = searchParms.get("filterCategory") || filterCategory.ALL
  const filterSubCategory = searchParms.get("filterSubCategory")

  const setFilterCategory = (value) => {
    setSearchParms(prev => {
      prev.set("filterCategory", value)
      prev.set("filterSubCategory", 'ALL')
      prev.set("searchQuery", '')
      return prev
    }, { replace: true })
  }

  const setFilterSubCategory = (cat, subCat) => {
    setSearchParms(prev => {
      prev.set("filterCategory", cat)
      prev.set("filterSubCategory", subCat)
      return prev
    }, { replace: true })
  }

  // useEffect(() => {
  //   setFilterSubCategory('ALL')
  // }, [mainCat, mainCategories])

  // const useEffect =( () =>{
  //   getSubCategories()
  //   .then(res => {
  //     const all = { _id: "ALL", name: 'All' }
  //     setsubCat([all, ...res])
  //   })
  //   .catch(error => console.error(error))

  // },[mainCat])

  return (
    <div className='categoryList py-2'>
      {/* <ScrollArea.Root className="ScrollAreaRoot">
        <ScrollArea.Viewport className="ScrollAreaViewport"> */}
      <div className='d-flex justify-content-center align-items-center flex-wrap'>
        {/* <div className="Text">Categories</div> */}


        {mainCategories.map((cat, index) => {
          const subCats = subCategories.filter(subCat => subCat?.parent === cat?._id)
          const className = cat?._id === mainCat ? 'categoryelementname selectedCatergory' : 'categoryelementname'
          return <div key={cat?._id}
            className='categoryelement'
          >
            <span className={className}
              onClick={() => {
                setFilterCategory(cat?._id)
              }}
            >
              {cat?.name}
            </span>
            {cat?._id !== "ALL" && <span className='dropdown'>
              <UnopDropdown
                onAppear={() => { }}
                onDisappearStart={() => { }}
                closeOnClickOut={true}
                delay={0}
                align="CENTER"
                hover={false}
                closeOnDropdownClicked

                trigger={
                  <img
                    className="categoryelementimage"
                    src={catimage}
                    alt=""
                  />
                }
              >
                <div className="card-filter1">
                  {
                    subCats.map(subCat => <div
                      // className="border-bottom card-filter-item1"
                      key={subCat?._id}
                      className={filterSubCategory === subCat?._id ? "border-bottom card-filter-item1 selectedSubCategory " : 'border-bottom card-filter-item1'}
                      onClick={() => {
                        setFilterSubCategory(cat?._id, subCat?._id)
                      }}
                    >
                      {subCat?.name}
                    </div>)}
                  {/* <div className="border-bottom card-filter-item1" >
                    Price assendant
                  </div>
                  <div className="border-bottom card-filter-item1">
                    Price descendant
                  </div>
                  <div className="border-bottom card-filter-item1" >
                    Discount product
                  </div> <div className="border-bottom card-filter-item1">
                    New product
                  </div> */}
                </div>
              </UnopDropdown>
            </span>}
          </div>
          // return <div key={cat?._id}
          //   className={mainCat === cat?._id ? "categoryCard selectedCategory " : 'categoryCard'}
          //   style={{backgroundColor: colors[index%colors.length]}}
          //   onClick={() => setFilterCategory(cat?._id)}>
          //   <span className=' categoryCardName'>
          //     {cat?.name}
          //   </span>
          //   <img src={cat?.image?.url} alt="" className='categoryCartImage' />
          // </div>
        })}
      </div>
      {/* </ScrollArea.Viewport>
        <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
          <ScrollArea.Thumb className="ScrollAreaThumb" />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
          <ScrollArea.Thumb className="ScrollAreaThumb" />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner className="ScrollAreaCorner" />
      </ScrollArea.Root> */}
    </div>
  )

}

// const SubCategoryList = () => {

//   const mainCategories = useSelector(state => state.mainCategories)
//   const [catInfo, setcatInfo] = useState({ _id: '', name: '' })
//   const [subCats, setsubCats] = useState([])

//   const [searchParms, setSearchParms] = useSearchParams()
//   const categoryId = searchParms.get("filterCategory")
//   const filterSubCategory = searchParms.get("filterSubCategory")

//   const setFilterSubCategory = (value) => {
//     setSearchParms(prev => {
//       prev.set("filterSubCategory", value)
//       return prev
//     }, { replace: true })
//   }

//   useEffect(() => {
//     setFilterSubCategory('ALL')

//     getSubCategories(categoryId)
//       .then(res => {
//         const all = { _id: "ALL", name: 'All' }
//         setsubCats([all, ...res])
//       })
//       .catch(error => console.error(error))

//     setcatInfo(mainCategories.find(item => item._id === categoryId))
//     //  console.log(servecies)
//   }, [categoryId, mainCategories])



//   return (
//     <div className='subCategoryList'>

//       <ScrollArea.Root className="ScrollAreaRoot">
//         <ScrollArea.Viewport className="ScrollAreaViewport">
//           <div className='d-flex justify-content-center align-items-center'>
//             {/* <div className="Text">Categories</div> */}
//             {subCats.map((subCat) => {
//               return <div key={subCat?._id}
//                 className={filterSubCategory === subCat?._id ? "subCategoryCard selectedSubCategory " : 'subCategoryCard'}
//                 onClick={() => setFilterSubCategory(subCat?._id)}>
//                 <span className=' subCategoryCardName'>
//                   {subCat?.name}
//                 </span>
//                 {/* <img src={cat?.image?.url} alt="" className='subCategoryCartImage'/> */}
//               </div>
//             })}
//           </div>
//         </ScrollArea.Viewport>
//         <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
//           <ScrollArea.Thumb className="ScrollAreaThumb" />
//         </ScrollArea.Scrollbar>
//         <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
//           <ScrollArea.Thumb className="ScrollAreaThumb" />
//         </ScrollArea.Scrollbar>
//         <ScrollArea.Corner className="ScrollAreaCorner" />
//       </ScrollArea.Root>
//     </div>
//   )

// }


export default StorePage2
