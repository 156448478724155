import React from 'react'
import { Col, Row } from 'react-bootstrap';
// import ReactStars from "react-rating-stars-component";
import './ratepost.css'
import { useState } from 'react';
import { getUserRating, rateProduct } from '../../actions/storeAction';
import { useEffect } from 'react';
import './product/rating/ratingStyle.css';
import { useDispatch, useSelector } from 'react-redux';
// import Rating from './product/rating/Rating';

const RatePost = ({ productId, setRateInfo }) => {

  const [ratingInfo, setRatingInfo] = useState(() => { })
  const [ratingValue, setRatingValue] = useState(0)
  const dispatch = useDispatch()
  const isLogedin = useSelector(state => state.isLogedin)
  const onChange = (index) => {
    // console.log(index)
    if (isLogedin) {
      rateProduct(productId, index)
        .then(res => {
          if (res.success) {
            // setRatingValue(index)
            setRateInfo(pre => {
              const count = ratingValue === 0 ? pre?.count + 1 : pre?.count
              const sum = pre?.rate * pre?.count + index - ratingValue
              setRatingValue(index)
              return { count, rate: sum / count }
            })
          }
        })
        .catch(error => console.error(error))
    } else {
      alert('please log in')
    }

  }
  // const [starsValue, setStarsValue] = useState(() => 5)

  useEffect(() => {
    getUserRating(productId)
      .then(res => {
        // console.log(res.data)
        setRatingInfo(pre => {
          // console.log('rating', res.data)
          return res.data
        })
      })
      .catch(error => console.error(error))
  }, [])

  useEffect(() => {
    setRatingValue(ratingInfo?.rate)
    // console.log('ratingInfo', ratingInfo)
    return () => {

    }
  }, [ratingInfo])


  // useEffect(() => {
  //   setStarsValue(ratingInfo?.rate)
  //   // console.log(starsValue)
  // }, [ratingInfo])

  // const setting = {
  //   size: 20,
  //   count: 5,
  //   color: "#979797",
  //   activeColor: "#ffc107",
  //   // value: 0,
  //   a11y: true,
  //   isHalf: true,
  //   // emptyIcon: <i className="far fa-star" />,
  //   // halfIcon: <i className="fa fa-star-half-alt" />,
  //   // filledIcon: <i className="fa fa-star" />,
  //   onChange: newValue => {
  //     setStarsValue(newValue)
  //     console.log(`Example 2: new value is ${newValue}`);
  //   }
  // }


  return (

    <div className='ratingStars d-flex justify-content-center'>
      {/* <span> {this.props.rating.userName}</span> */}

      {[...Array(5)].map((_, i) => {
        const value = i + 1;
        return (
          <span key={i} className=' '>
            <input type="radio" name="rating" value={value} className=' d-none' />
            <label
              className={value <= ratingValue ? 'checked ' : ' '}
              onClick={() => onChange(value)}
            >★</label>
          </span>
        );
      })}
      {/* <span> {this.props.rating.count}</span> */}
    </div>
  )

  // return (
  //   <div>
  //     <Row className="mt-2 ">
  //       <Col sm="12" className=" d-flex">

  //         {/* <div className="rate-name  d-inline ms-3 mt-1 ">{ratingInfo?.userName}</div> */}

  //         <Rating rating={ratingInfo} productId={productId} setRateInfo={setRateInfo}/>

  //         {/* <ReactStars {...setting} value={starsValue} /> */}
  //         {/* {console.log(ratingInfo?.rate)} */}

  //       </Col>
  //     </Row>

  //     {/* <Row className="border-bottom mx-2">
  //         <Col className="d-felx me-4 pb-2">
  //           <textarea
  //             className="input-form-area p-2 mt-3"
  //             rows="2"
  //             cols="20"
  //             placeholder=" write a comment...."
  //           />
  //           <div className=" d-flex justify-content-end al">
  //             <div className="product-cart-add px-3  py-2 text-center d-inline">اضف تعليق</div>
  //           </div>
  //         </Col>
  //       </Row> */}
  //   </div>
  // )
}

export default RatePost