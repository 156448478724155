import React from 'react'
import { Col, Row } from 'react-bootstrap'
import RateContainer from '../RateContainer'
import ProductDescreption from './ProductDescreption'
import ProductGallery from './ProductGallery'
import ProductText from './ProductText'



const ProductDetalis = ({ product , productId}) => {


    return (
        <div style={{ marginTop: "100px" }}>
            <Row className='py-3'>
                <Col lg="6">
                    <ProductGallery images={product?.images}/>
                </Col>

                <Col lg="6">

                    <ProductText product={product} />
                    <RateContainer rating={product.rating} productId={productId} />
                    
                </Col>
            </Row>
            <ProductDescreption title = {product.name} description = {product.description}/>
        </div>
    )
}

export default ProductDetalis