import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"

const updateUser = (userData) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/updateUser'
        fetch(url, {
            method: 'PATCH',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify(userData)
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

const getUser = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getUser'
        fetch(url, {
            // credentials: 'include'
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

const getUserTransaction = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getUserTransaction'
        fetch(url, {
            // credentials: 'include'
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                data?.success ? resolve(data) : reject(data)

            })
            .catch(error => console.error(error))
    })
}

export {
    updateUser,
    getUser,
    getUserTransaction
} 
