import React from "react";
import './heroSection.css'
import { Link } from "react-router-dom";
import GoToStoreButton from "../../Page/MainHome/GoToStoreButton";

const HeroSection = () => {
  return (
    <div id="heroSection">
      <div className="hero vh-100 d-flex align-items-center" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto text-center">
              <h1 className="display-4 text-white hero-title">
                {/* Build robust landing pages now */}
                Welcome to our world
              </h1>
              <p className="text-white my-3">
                We advise you to take a look at our store, there may be something that may catch your eye, or you need it.
              </p><GoToStoreButton />
              {/* <a href="#" className="btn me-2 btn-primary">
                Get Started
              </a> */}
              
              {/* <Link to="/StorePage2" className="btn btn-outline-light">
                Go to Store
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default HeroSection;
