import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"

const getNProducts = (cat, page, number, parent, sort, searchQuery) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getNProducts'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ category: cat, page, number, parent, sort, searchQuery })
        })
            .then(res => res.json())
            .then(json => {
                json?.success ? resolve(json.data) : reject(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}


export {
    getNProducts,

}