import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './slider.css';
import { Link } from 'react-router-dom';

const Slider = () => {
    const [index, setIndex] = useState(0)
    const [screenwidth, setscreenwidth] = useState(window.innerWidth)
    // const [image, setimage] = useState("")
    const slider = useSelector(state => state.slider);
    useEffect(() => {

    }, [slider])

    useEffect(() => {
        const handleResize = () => {
          setscreenwidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Nettoyer l'écouteur d'événement lors du démontage du composant
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    //   useEffect(() => {
    //     // Mettre à jour votre état en fonction de la taille de l'écran
    //     if (screenwidth < 1000) {
    //         setimage(item?.image[1])
    //     } else {
    //         setimage(item?.image[0])
    //     }
    //   }, [screenwidth]);

    // useEffect(() => { }, [slider])

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex)

    }
    return (
        <>
            {slider.length > 0 && <Carousel activeIndex={index} onSelect={handleSelect} style={{ height: "250px" }} >

                {slider.map(item => <Carousel.Item className="slider-background" interval={2000} style={{ background: "blue" }}>
                    {/* <div className="d-flex flex-row justify-content-center align-items-center ">
                    <img
                        style={{ height: "200px", width: "auto" }}
                        className=""
                        src={item?.image}
                        alt="First slide"
                    />
                    <div className="">
                        <h3 className="slider-title">{item?.title} </h3>
                        <p className="slider-text">{item?.description}</p>
                    </div>
                </div> */}
                    {/* aspectRatio: "16/9"  */}
                    <Link to={item?.linkTo} >
                        {/* <picture >
                            <source media="(max-width: 800px)"srcSet={item?.image[1]} />
                            <source media="(min-width: 801px)" srcSet={item?.image[0]} />
                        </picture> */}
                        <img src={screenwidth>=800? item?.image[0]:item?.image[1]} style={{width: '100%', height: "250px" }} />
                    </Link>
                </Carousel.Item>)}
            </Carousel>}
        </>
    );
}

export default Slider


// const SliderItem = ({ item }) => {
//     return (
//         <Carousel.Item className="slider-background pt-5" interval={2000} style={{ background: item?.color ? item?.color : "#ffd5e7" }}>
//             <div className="d-flex flex-row justify-content-center align-items-center mt-3 ">
//                 <img
//                     style={{ height: "200px", width: "auto" }}
//                     className=""
//                     src={item?.image}
//                     alt="First slide"
//                 />
//                 <div className="">
//                     <h3 className="slider-title">{item?.title}</h3>
//                     <p className="slider-text">{item?.description}</p>
//                 </div>
//             </div>
//         </Carousel.Item>
//     )
// }
