import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './ratecontainer.css';

// import RateItem from './RateItem';

// import Pagination from '../Utility/Pagination';
import { useEffect, useState } from 'react';
import RatePost from './RatePost';
const RateContainer = ({ rating, productId }) => {

    const [rateInfo, setRateInfo] = useState()

    useEffect(() => {
        setRateInfo(rating)
    }, [rating])

    return (
        // <Container className='rate-container'>
        //      <Row>
        //         <Col className="d-flex">
        //             <div className="sub-tile d-inline p-1 ">ratings</div>
        //             <img className="mt-2 starimage" src={rate} alt=""/>
        //             <div className="cat-rate  d-inline  p-1 pt-2">{rateInfo?.rate}</div>
        //             <div className="rate-count d-inline p-1 pt-2">{rateInfo?.count}</div>
        //             <div className=''>2.6</div>
        //         </Col>
        //     </Row>
        //     <RatePost productId={productId} setRateInfo={setRateInfo}/>
        //     <RateItem />
        //     <RateItem />
        //     <RateItem />
        //     <RateItem /> 

        //     <Pagination/>
        // </Container>
        <Row className="mt-4">
            <Col xs="6" style={{ borderRight: "2px solid  var(--gold)", textAlign: "center" }}>
                <div className='productTitle'>Overall rating</div>
                <div className='rate-totale'>{rateInfo?.rate.toFixed(2)}</div>
                <div className="">Based on {rateInfo?.count} ratings </div>
            </Col>
            <Col xs="6 " className='d-flex flex-column align-items-center text-center'>
                <div className='productTitle'>Rate this product</div>
                <div className=" ">
                    <RatePost productId={productId} setRateInfo={setRateInfo} />
                </div>
            </Col>
        </Row>
    )
}

export default RateContainer