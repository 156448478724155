import React from 'react'
import ProductCartSceleton from '../../../Sceleton/ProductCartSceleton'
import { Row } from 'react-bootstrap'
import Shimmer from '../../../Sceleton/Shimmer'
import SkeletonElement from '../../../Sceleton/SkeletonElement'
import Slider from 'react-slick'
import { useEffect } from 'react'
import { useState } from 'react'

const ProductSliderSceleton = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const slideNb = Math.min(Math.ceil(windowWidth / 220), 5)

    const settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: slideNb,
        slidesToScroll: 1
    };
    return (
        <div className='productSlider2 mt-3 overflow-hidden w-100' >

            <div className=' sliderBody'>
                <div className=" d-flex justify-content-between align-items-center">
                    <SkeletonElement type="small-box-sceleton" />
                 
                    <SkeletonElement type="small-button-sceleton" />
                   
                </div>
                <Slider {...settings} autoplay autoplaySpeed={3000}  className='d-flex flex-wrap overflow-hidden' style={{ height: "350px" }}>
                    {[1, 2, 3, 4, 5].map(item => <ProductCard key={Math.random()} />)}
                </Slider>
            </div>
            
        </div>
    )
}

export default ProductSliderSceleton

const ProductCard = () => {
    return (<>
        <div className="card-store3 text-center ">
            <div className="my-2  parent-productslider" >
                <div className=' d-flex justify-content-center' >
                    <SkeletonElement type="avatar" />
                </div>
                <div className=" pt-3">
                    <SkeletonElement type="title"  />
                </div>


            </div>
            <Shimmer />
        </div></>
        // <ProductCartSceleton/>

    )
}
