const pageEnum = {
    HOMEPAGE: "HOMEPAGE",
    PRODUCTDETAILSPAGE: "PRODUCTDETAILSPAGE",
    STOREPAGE: "STOREPAGE",
    FAVORITEPAGE: "FAVORITEPAGE",
    CARTPAGE: "CARTPAGE",
    PROFILEPAGE: "PROFILEPAGE",
    TRANSACTIONPAGE: "TRANSACTIONPAGE"
}

export default pageEnum