import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import deleteicon from '../../../assets/icon/edit.png'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Cookies from 'js-cookie'
import reduxActionTypes from '../../../enums/reduxActionTypes'
import { login, logout } from '../../../actions/signUpActions'
import './userprofile.css'
import ChangePass from './ChangePass'
import { updateUser } from '../../../actions/profileAction'
import { Navigate, useNavigate } from 'react-router-dom'
import email from "../../../assets/icon/emailLogo.png"
import buttom from "../../../assets/icon/forbuttom.png"
import top from "../../../assets/icon/fortop.png"

const UserProfile = () => {


    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    // const cookie = Cookies.get('user')
    const dispatch = useDispatch()
    const [name, setname] = useState()
    const [phone, setphone] = useState()
    const [location, setlocation] = useState()
    const [isedit, setisedit] = useState(false)
    const [changePwd, setchangePwd] = useState(false)

    useEffect(() => {

        setname(user.name)
        setphone(user.phone)
        setlocation(user.location)
        // console.log(user)
    }, [user])

    const editUser = () => {
        const data = {
            // _id: user._id,
            name,
            phone,
            location,
        }
        updateUser(data)
            .then(resolve => {
                console.log("user updated")
                
                // dispatch({ type: reduxActionTypes.UPDATEUSER, data })
                // console.log("updated User: ", user)
            }, reject => {
                console.error(reject.msg)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setisedit(false))
    }

    return (


        <Row className='userprofile d-flex justify-content-center pb-4'>
            <div className='col-12 col-md-9'>
                <div className=" d-flex justify-content-end pb-2">
                    <button className="nav-link button-profile" onClick={() => logout(dispatch, navigate)}>Logout</button>
                </div>
                {/* <Row className=""> */}
                <div className="d-flex justify-content-between pt-2 header-profile">
                    <Col xs="6" className="" style={{ fontSize: '20px' }}>{user?.name?.split(" ")[0]} profile</Col>
                    <Col xs="6" className="d-flex justify-content-end">
                        <div className="d-flex" onClick={() => setisedit(pre => !pre)} style={{ cursor: "pointer" }}>
                            <img
                                alt=""
                                className="ms-1 mt-2"
                                src={deleteicon}
                                height="17px"
                                width="15px"

                            />
                            <p className="">Edit</p>
                        </div>
                    </Col>
                </div>
                <div className='body-profile'>
                    <Row className='d-flex align-items-center'>
                        <Col xs="3" md="2">
                            <div className="p-2 ">Name:</div>
                        </Col>
                        <Col xs='9' md="10">
                            {/* <div className="p-1 item-delete-edit" contentEditable={isedit} onInput={(e) => setname(e.target.textContent)}>{name} </div> */}
                            <input className="p-1 input-profile" style={{ border: isedit ? '1px solid black' : 'none' }} disabled={!isedit} onChange={(e) => setname(e.target.value)} value={name} />
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Col xs="3" md="2">
                            <div className="p-2 ">Phone:</div>
                        </Col>
                        <Col xs='9' md="10">
                            {/* <div className="p-1 item-delete-edit" contentEditable={isedit} onInput={(e) => setphone(e.target.textContent)}>{phone}</div> */}
                            <input className="p-1 input-profile" style={{ border: isedit ? '1px solid black' : 'none' }} disabled={!isedit} onChange={(e) => setphone(e.target.value)} value={phone} />
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Col xs="3" md="2" >
                            <div className="p-2 ">Location:</div>
                        </Col>
                        <Col xs='9' md="10" >
                            {/* <div className="p-1 item-delete-edit" contentEditable={isedit} onInput={(e) => setlocation(e.target.textContent)}>{location}</div> */}
                            <input className="p-1 input-profile" style={{ border: isedit ? '1px solid black' : 'none' }} disabled={!isedit} onChange={(e) => setlocation(e.target.value)} value={location} />
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                        {/* <Col xs="3" md="2">
                            <div className="p-2"></div>
                        </Col>
                        <Col xs='9' md="10">
                            <label className="p-1 " value={user.email} >{user.email}</label>
                        </Col> */}
                        <Col xs="3" md="2" >

                            <div className="p-2">Email:
                                {/* <span className=' smile-face'>
                                <img src={email} className=' email-logo' /> 
                                </span> :  */}
                            </div>
                        </Col>
                        <Col xs='9' md="10" >
                            <span style={{ color: "var(--colorTitle)" }}>{user.email}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="d-flex justify-content-center ">
                            <button className=" button-profile d-inline mt-2 " onClick={editUser}>submit</button>
                        </Col>
                    </Row>

                    <Row className='mt-3'><div className="pass-title" > change your pass : <span className='clickHear' onClick={() => setchangePwd(pre => !pre)}>click hear <img src={changePwd ? top : buttom} className='click-buttom' /></span></div></Row>
                    {/* <Row className='mt-3'><div className="pass-title" > view transaction : <span className='clickHear' onClick={() => setviewTransaction(pre => !pre)}>click hear <img src={viewTransaction ? top:buttom} className='click-buttom'/></span></div></Row> */}

                    {changePwd && <ChangePass user={user} />}
                </div>
                {/* </Row> */}
            </div>
        </Row>
    )
}

export default UserProfile