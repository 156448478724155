import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import { getUserTransaction } from '../../actions/profileAction'
import { setOpenedPage } from '../../actions/urlService'
import buttom from '../../assets/icon/forbuttom.png'
import top from '../../assets/icon/fortop.png'
import transactionImage from '../../assets/icon/transaction2.png'
import pageEnum from '../../enums/pageEnum'
import reduxActionTypes from '../../enums/reduxActionTypes'
import './transaction.css'


// import { changeStateByUser } from '../../actions/storeAction'
import orderStatus from '../../enums/orderStatus'
import { TransctionBill } from '../../Component/Transaction/TransctionBill'
import { getProductsByKeys } from '../../actions/transactionAction'

const TransactionPage = () => {
  const dispatch = useDispatch();
  const userTransaction = useSelector(state => state.userTransaction)
  const isLogedin = useSelector(state => state.isLogedin)
  // const [moreDetails, setmoreDetails] = useState(false)

  //


  useEffect(() => {

    setOpenedPage(pageEnum.TRANSACTIONPAGE, dispatch)

    getUserTransaction()
      .then(
        resolve => {
          dispatch({ type: reduxActionTypes.SETUSERTRANSACTION, value: resolve.data })
          console.log('resolve.data', resolve.data)
        }

      )
      .catch(error => console.error(error))
  }, [])



  return (

    <div className='transactionPage '>
      <StoreNavBar />
      {userTransaction.length !== 0 ?

        <Container >
          <div className='d-flex flex-column align-items-center'>
            {userTransaction.map(trans => {
              return <TransactionCard transaction={trans} />
            })}
          </div>
        </Container>
        :
        <Container className='d-flex align-items-center flex-column pb-5 fav-header2'>
          <span className='image-fav'><img src={transactionImage} /> </span>
          <h1 className='title-fav'>You don't have any transaction</h1>
          <p className='text-fav'>What are you waiting for!</p>
          {isLogedin ?
            <Link className='button-fav' to="/storePage2">START SHOPPING</Link> :
            <Link className='button-fav' to="/login">Go to Login!</Link>
          }
        </Container>
      }
    </div>

  )
}

// const handleClick = () => {
//   const phoneNumber = '+96178833781';
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
//   if (isMobile) {
//     window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
//     return
//   }
//   window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
// }

const TransactionCard = ({ transaction }) => {
  const [moreDetails, setmoreDetails] = useState(false);
  const userTransaction = useSelector(state => state.userTransaction)
  const dispatch = useDispatch()
  // const [status, setStatus] = useState(transaction?.status)

  //// 2/12/2023
  const [products, setproducts] = useState([])

  useEffect(() => {
    if (moreDetails) {
      const cartArray = transaction?.cart?.map(p => p?.productKey).sort()
      const productsArray = products?.map(p => p?.key).sort()
      if (JSON.stringify(cartArray) !== JSON.stringify(productsArray)) {
        getProductsByKeys(cartArray)
          .then(resolve => {
            // console.log('products:', resolve)
            setproducts(resolve)
          }, reject => {
            console.log('Error', reject?.msg)
          })
          .catch(error => console.error(error))
      }
    }
  }, [moreDetails])
  ////

  // const removeFromUser = () => {
  //   changeStateByUser(transaction._id, orderStatus.REMOVE_FROM_USER)
  //     .then(resolve => {
  //       dispatch({
  //         type: reduxActionTypes.SETUSERTRANSACTION,
  //         value: userTransaction.filter(t => t?._id !== transaction?._id)
  //       })
  //       console.log('transaction removed successfully', transaction?._id)
  //     })
  //   // setStatus(orderStatus.REMOVE_FROM_USER)
  // }
  return (<>
    {transaction?.status !== orderStatus.REMOVE_FROM_USER ? <div className='col-12 col-lg-8'>
      <div className='transaction pb-4'>
        <div className='row titleSectionTransaction d-flex  justify-content-center'>
          <div className='col-12 '> Order:  {transaction?.key}</div>
        </div>
        <Row className='bodySectionTransaction '>
          <div className='col-12 d-flex flex-column'>
            <div className='d-flex justify-content-center '>
              <span className='status'> {transaction?.status}...</span><span className='dateInTransaction pt-2'>({transaction?.updatedAt.split('T')[0]})</span>
            </div>

            <div className='admin-comment mb-1'>
              {transaction?.adminComment ? transaction?.adminComment : transaction?.status}
            </div>
              <div className=' d-flex justify-content-end'>
                <span className='clickHear' onClick={() => setmoreDetails(pre => !pre)}>
                  <span>Details </span>
                  <img src={moreDetails ? top : buttom} className='click-buttom' />
                </span>
              </div>


          </div>
          {moreDetails &&
            <TransctionBill transaction={transaction} products={products} />
          }
        </Row>
      </div>
    </div>
      :
      null
    }
  </>
  )
}

export default TransactionPage
