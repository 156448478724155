import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from '../../../actions/storeAction'
import reduxActionTypes from '../../../enums/reduxActionTypes'
import './producttext.css'
import pricingMethod from '../../../enums/pricingMethod'

const ProductText = ({ product }) => {

  const [selectedColor, setselectedColor] = useState(() => 0)
  const [selectedSize, setselectedSize] = useState(() => 0)
  const [selectedMaterial, setselectedMaterial] = useState(() => 0)
  const [selectedQuantity, setselectedQuantity] = useState(() => 1)
  const [price, setprice] = useState(() => 0)
  const [stockQuantity, setStockQuantity] = useState(0);

  const dispatch = useDispatch()
  const isLogedin = useSelector(state => state.isLogedin)

  const handleAddToCart = () => {
    if (isLogedin) {
      addToCart(product._id, product.color[selectedColor], selectedQuantity, product.size[selectedSize], product.material[selectedMaterial], price)
        .then((resolve) => {
          resolve.data.success && alert('Add to cart success')
          dispatch({ type: reduxActionTypes.INCCARTCOUNT })
        })
        .catch(error => console.error(error))
    } else {
      alert('please log in')
    }

  }

  useEffect(() => {
    setselectedQuantity(product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[0].min : 1)
  }, [product])

  const handlePriceChange = () => {
    let currentPrice = { priceValue: 0 }
    switch (product.pricingMethod) {
      case pricingMethod.STATIC:
        currentPrice = product?.price[0]
        break;
      case pricingMethod.COLOR:
        currentPrice = product?.price.find(p =>
          p?.color === product?.color[selectedColor]
        )
        break;
      case pricingMethod.MATERIAL:
        currentPrice = product?.price.find(p =>
          p?.material === product?.material[selectedMaterial]
        )
        break;
      case pricingMethod.SIZE:
        currentPrice = product?.price.find(p =>
          p?.size === product?.size[selectedSize]
        )
        break;
      case pricingMethod.MATERIAL_COLOR:
        currentPrice = product?.price.find(p =>
          p?.material === product?.material[selectedMaterial] &&
          p?.color === product?.color[selectedColor]
        )
        break;

      case pricingMethod.MATERIAL_SIZE:
        currentPrice = product?.price.find(p =>
          p?.material === product?.material[selectedMaterial] &&
          p?.size === product?.size[selectedSize]
        )
        break;
      case pricingMethod.SIZE_COLOR:
        currentPrice = product?.price.find(p =>
          p?.size === product?.size[selectedSize] &&
          p?.color === product?.color[selectedColor]
        )
        break;

      case pricingMethod.MATERIAL_SIZE_COLOR:
        currentPrice = product?.price.find(p =>
          p?.material === product?.material[selectedMaterial] &&
          p?.size === product?.size[selectedSize] &&
          p?.color === product?.color[selectedColor]
        )
        break;
      case pricingMethod.QUANTITY:
        currentPrice = product?.price.find(p =>
          p?.min <= selectedQuantity &&
          p?.max >= selectedQuantity
        );
        break;

      default:
        currentPrice = product?.price.find(p =>
          p?.material === product?.material[selectedMaterial] &&
          p?.size === product?.size[selectedSize] &&
          p?.color === product?.color[selectedColor]
        )
        break;
    }
    setprice(currentPrice?.priceValue)
  }

  useEffect(() => {
    // console.log('productText', product)
    handlePriceChange()
  }, [selectedColor, selectedMaterial, selectedSize, product, selectedQuantity])

  useEffect(() => {
    setStockQuantity(
      product?.stock?.find(item => item?.material === product?.material[selectedMaterial] &&
        item?.size === product?.size[selectedSize] &&
        item?.color === product?.color[selectedColor])?.quantity
    )
  }, [selectedColor, selectedMaterial, selectedSize, product])

  // useEffect(() => {
  //   console.log('selectedColor', selectedColor)
  // }, [selectedColor])

  // useEffect(() => {
  //   console.log('selectedSize', selectedSize)
  // }, [selectedSize])


  return (
    <div>
      <Row className="mt-1">

        <div className='productTitleMain'>{product?.name}</div>
      </Row>

      <Row>
        <Col md="8" className="mt-2">
          <Row>
            <span className='productTitle col-4'>ID: </span>
            <span className='productValue col-8'>{product?.key}</span>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="8" className="mt-2">
          <Row>
            <span className='productTitle col-4'> Price: </span>
            <div className='col-8'>
              {product?.discount === 0 ? <span className='productValue' > {price}$ </span> :
                <> <span className='productValue' ><del> {price}$ </del></span>
                  <span className='productValue'> {price * (100 - product?.discount) / 100}$ </span>
                  <span className='productValue'>({product?.discount}% off)</span></>}
            </div>
          </Row>
        </Col>
      </Row>


      {product?.color?.length !== 0 && <Row>
        <Col md="8" className="mt-2">
          <Row>
            <span className='productTitle col-4'> Color: </span>
            <div className='d-flex align-items-center col-8'>
              {
                product?.color?.map((col, index) => {
                  return <div
                    key={index}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: col,
                      borderRadius: "50%",
                      marginInline: "5px",
                      border: index === selectedColor ? "2px solid var(--colorTitle)" : "none",
                    }}
                    onClick={() => setselectedColor(index)}
                  ></div>
                })
              }
            </div>
          </Row>
        </Col>
      </Row>}
      {product?.size?.length !== 0 && <Row>
        <Col md="8" className="mt-2">
          <Row className='d-flex align-items-center'>
            <span className='productTitle col-4' > Size: </span>
            <div className="col-8">
              <select className="form-select" aria-label="Default select example" value={selectedSize} onChange={(e) => setselectedSize(e.target.value)}>
                {product?.size?.map((s, index) => {
                  return <option key={Math.random()} value={index} >{s}</option>
                })}
              </select>
            </div>
          </Row>
        </Col>
      </Row>}

      {product?.material?.length !== 0 && <Row>
        <Col md="8" className="mt-2">
          <Row className='d-flex align-items-center'>
            <span className='productTitle col-4' > Material: </span>
            <div className="col-8">
              <select class="form-select" aria-label="Default select example" value={selectedMaterial} onChange={(e) => setselectedMaterial(e.target.value)}>
                {product?.material?.map((s, index) => {
                  return <option key={Math.random()} value={index} >{s}</option>
                })}
              </select>
            </div>
          </Row>
        </Col>
      </Row>}
      {
        product?.inStock &&
        <Row>
          <Col md="8" className="mt-2">
            <Row>
              <div className='productTitle col-4' >Stock:</div>
              <div className="productValue col-8">{stockQuantity === 0 ? 'out of stock' : stockQuantity}</div>
            </Row>
          </Col>
        </Row>
      }
      {
        product?.pricingMethod === pricingMethod.QUANTITY &&
        <div className=' d-flex flex-column mt-1'>
          <div className=' d-flex justify-content-between box-quantity-price py-1' style={{ color: "var(--colorTitle)", fontWeight: "bolder" }}>
            <span style={{ flex: 1 }}>From</span>
            <span style={{ flex: 1 }}>To</span>
            <span style={{ flex: 1 }}> Value</span>
          </div>
          {product?.price?.map(p => <div className=' pe-2'>
            <div className=' d-flex justify-content-between box-quantity-price py-1' >
              <span style={{ flex: 1 }}>{p?.min}</span>
              <span style={{ flex: 1 }}>{p?.max}</span>
              <span style={{ flex: 1 }}> {p?.priceValue}$</span>
            </div>
          </div>)}
        </div>
      }
      <Row className="mt-4">
        <Col md="12">
          <input className="d-inline px-1 py-1 border product-number"
            type="number" pattern="^[1-9]\d*"
            value={selectedQuantity}
            onChange={(e) => setselectedQuantity(() => {
              // const n = Math.abs(+e.target.value)
              // const min = product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[0].min : 1
              // const max = product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[product?.price.length - 1].max : 1000000
              // return n > min ? (n < max ? n : max) : min
              return +e.target.value
            })}
            onBlur={() => {
              setselectedQuantity(() => {
                const n = Math.abs(selectedQuantity)
                const min = product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[0].min : 1
                const max = product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[product?.price.length - 1].max : 1000000
                return n > min ? (n < max ? n : max) : min
              })
            }}
            min={product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[0].min : 1}
            max={product?.pricingMethod === pricingMethod.QUANTITY ? product?.price[product?.price.length - 1].max : 1000000}
          />
          <button
            className="btn ms-3 fw-bold px-3 py-2 add-btn"
            onClick={handleAddToCart}
          // disabled={product?.inStock && product?.quantity <= 0}
          >
            Add to Cart
          </button>
        </Col>
      </Row>



    </div>
  )
}

export default ProductText