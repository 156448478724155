import React from 'react';
import './whatsapp.css';
import whatsapp from "../../assets/logo/whatsapp.png"
import { useEffect } from 'react';
import { useState } from 'react';

const Whatsapp = () => {
    const phoneNumber = '+96178833781';


    //
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Vérifie si le navigateur de l'utilisateur est un appareil mobile
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
    }, []);
    //
    const handleClick = () => {
        if (isMobile) {
            window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
            return
        }
        window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}`, '_blank');

    };

    return (
        <div className="whatsapp-button" onClick={handleClick}>
            <img src={whatsapp} alt="WhatsApp Icon" />
        </div>
    );
};

export default Whatsapp;