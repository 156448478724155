import { clear } from '@testing-library/user-event/dist/clear';
import React, { useState, useEffect } from 'react';
import { signUp } from '../../actions/signUpActions';
import { useSelector } from 'react-redux';

const Counter = () => {
    const [time, setTime] = useState(120);
    let timer = null


    // Temps initial en secondes
    const [msg, setmsg] = useState('')
    const user = useSelector(state => state.user)

    const reset = () => {
        setTime(120)
        setmsg('')
        timer = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime - 1 === 0) {
                    clearInterval(timer)
                    setmsg(<a style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={resendCode}>resend code</a>)
                }
                return prevTime - 1
            });
        }, 1000);
    }

    const resendCode = () => {
        signUp(user).then(
            resolve => {
                {
                    reset()
                }
            },
            reject => {
                console.log(reject?.msg)
            }
        )
    }
    // Fonction pour convertir les secondes en format minutes:secondes
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };


    useEffect(() => {
        // Décrémente le temps toutes les secondes
        reset()

        // Nettoyage du timer lorsque le composant est démonté
        return () => clearInterval(timer);
    }, []);

    return (
        <div className='counter'>
            <p>Time left: <span>{formatTime(time)}</span> <span>{msg}</span></p>
        </div>
    );
};

export default Counter;
