import reduxActionTypes from "../enums/reduxActionTypes"

const getBaseApiURL = () => {
    const hostname = window.location.hostname
    if (hostname.includes('localhost'))
        return window.location.protocol + "//" + window.location.hostname + ":4000/api"
    // return "https://backend-kappa-one.vercel.app/api"
    return "https://backend-krayemco.vercel.app/api"
}

const setOpenedPage = (page, dispatch) => {
    dispatch({ type: reduxActionTypes.SETOPENEDPAGE, value: page })
}

export {
    getBaseApiURL,
    setOpenedPage
}