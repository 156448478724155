import React, { useState } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import './productgallery.css';
import emptyImage from '../../../assets/images/empty_image.png'


// import LeftButton from '../../assets/images/prev.png';
// import RightButton from '../../assets/images/next.png';
// import './productgallery.css'

// const ProductGallery = () => {
//     const images = [
//         {
//             original: `${mobile2}`,
//         },
//         {
//             original: `${mobile2}`,
//         },
//         {
//             original: `${mobile2}`,
//         },
//     ];
//     return (
//         <div className="product-gallary-card d-flex justfiy-content-center  align-items-center
//         pt-2">
//             <ImageGallery items={images}
//                 defaultImage={mobile2}
//                 showFullscreenButton={false}
//                 isRTL={true}
//                 showPlayButton={false}
//                 showThumbnails={false}
//                 // renderRightNav={RightButton}
//                 // renderLeftNav={LeftButton}
//             />
//         </div>
//     )
// }

// export default ProductGallery
// const images = [pro,y1,y2,y3]
const ProductGallery = ({images}) => {
    const [mainImage , setmainImage] =useState(0)
    return (
        <div className='ProductGallery'>
            <div className='container1'>
                <img src={images[mainImage]??emptyImage} className='image1'/>
            </div>
            <div className='container2'>
                <img src={images[0]??emptyImage} className='image2' onClick={()=>setmainImage(0)}/>
              <img src={images[1]} className='image2' onClick={()=>setmainImage(1)}/>
                <img src={images[2]} className='image2' onClick={()=>setmainImage(2)}/>
            </div>


        </div>
    )


}
export default ProductGallery