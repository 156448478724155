import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Contact from '../../Component/MainHomeComponent/Contact'
import Features from '../../Component/MainHomeComponent/Features'
import HeroSection from '../../Component/MainHomeComponent/HeroSection'
import Services from '../../Component/MainHomeComponent/Services'
import GuestNavbar from '../../Component/NavBar/GuestNavBar'
import Whatsapp from '../../Component/whatsapp/Whatsapp'
import reduxActionTypes from '../../enums/reduxActionTypes'

const MainHomePage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLogedin = useSelector(state => state.isLogedin)
  const cookie = Cookies.get("user")

  // else alert('You are not loged in')
  useEffect(() => {
    console.log('isLogedIn: ' + isLogedin)
  }, [isLogedin])


  useEffect(() => {

    let user
    if (cookie) {
      dispatch({ type: reduxActionTypes.LOGIN })
      // user = JSON.parse(cookie)
      // login(user).then(
      //   resolve => {
      //     {
      //       console.log(resolve?.msg)
      //       dispatch({ type: reduxActionTypes.LOGIN })
      //       dispatch({type: reduxActionTypes.SETUSER, user: resolve.data})
      //       console.log(resolve.data)
      //       // Cookies.set('user', JSON.stringify({email,password}), {expires: 365})
      //       navigate('/')
      //     }
      //   },
      //   reject => { console.log(reject?.msg) }
      // )
    }
  }, [])

  return (
    <>
    <GuestNavbar/>
      <HeroSection />
      <Services />
      {/* <Features /> */}
      <Contact />
    </>
  )
}

export default MainHomePage
