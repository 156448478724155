import React from 'react'
import './productdescreption.css'

const ProductDescreption = ({title , description}) => {
  return (
    <div className='productdescription'>
      <h2 className='title-description'>Description</h2>
      {/* <p className='p-description'>{description}</p> */}
      <div className='p-description' dangerouslySetInnerHTML={{ __html: description}}></div>
      
    </div>
  )
}

export default ProductDescreption
