import React from 'react'
import { Container } from 'react-bootstrap'
// import CategoryHeader from '../../Components/Category/CategoryHeader'
// import CardProductsContainer from '../../Components/Products/CardProductsContainer'
// import ProductDetalis from '../../Components/Products/ProductDetalis'
// import RateContainer from '../../Components/Rate/RateContainer'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import StoreNavBar from '../../Component/NavBar/StoreNavBar'
import ProductDetalis from '../../Component/store/product/ProductDetalis'
import { getProductById } from '../../actions/storeAction'
import { setOpenedPage } from '../../actions/urlService'
import pageEnum from '../../enums/pageEnum'

const ProductDetalisPage = () => {
    const { productId } = useParams()

    const [product, setProduct] = useState(() => { return { rating: { count: 0, rate: 0 }, price: [] , images: []} })
    const dispatch = useDispatch()
    // const products = useSelector(state => state.products)

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        setOpenedPage(pageEnum.PRODUCTDETAILSPAGE, dispatch)
        getProductById(productId)
            .then(res => {
                console.log('product', res.data)
                setProduct(res.data)
            })
            .catch(error => console.error(error))
        // setProduct(pre => {
        //     const pro = products.find(pro => pro._id === productId)
        //     console.log(pro)
        //     return pro
        // })
    }, [productId])
    

    return (
        <div style={{ minHeight: '670px' }} key={productId}>
            {/* <CategoryHeader /> */}
            <StoreNavBar />
            <Container>
                <ProductDetalis product={product} productId={productId} />
                {/* <RateContainer rating={product.rating} productId={productId} /> */}
                {/* <CardProductsContainer title="Products You May Like " /> */}

            </Container>
        </div>
    )
}

export default ProductDetalisPage