import React from 'react'
import './transactionBill.css'
import { Container, Row } from 'react-bootstrap'
import pricingMethod from '../../enums/pricingMethod'

export const TransctionBill = ({ transaction, products }) => {

    const cart = transaction?.cart
    const overallDiscount = transaction?.overallDiscount
    const deliveryCost = transaction?.deliveryCost


    const itemDescriptionTag = (item, index) => {
        const name = item?.productName === undefined ? "???" : `${item.productName}`
        const color = item.color
        const size = item.size
        const material = item.material

        return <div className=' d-flex flex-wrap'>
            <span className=' me-1'>{name}</span>
            {item?.color === undefined ? "" :
                <span className=' d-flex align-items-center '>
                    {"|"}
                    <label htmlFor='color' className=' selectedColor mx-1' style={{ backgroundColor: color }}></label>
                </span>
            }
            {item?.size === undefined ? "" :
                <span>
                    {"|"}
                    <span className='mx-1'>{size}</span>
                </span>
            }
            {item?.material === undefined ? "" :
                <span>
                    {"|"}
                    <span className=' ms-1'>{material}</span>
                </span>
            }
        </div >
    }

    const getDiscount = (item, index) => {
        return cart.find((val, index2) => val?.productId === item?.productId && index === index2)?.productDiscount ?? 0
    }

    const getQuantity = (item, index) => {
        return cart.find((val, index2) => val?.productId === item?.productId && index === index2)?.quantity ?? 1
    }

    // const handlePriceChange = (item) => {
    //     const product = products?.find(p => p?.key === item.productKey)
    //     // console.log('PricingMethod', product?.pricingMethod)
    //     let currentPrice = { priceValue: 0 }
    //     switch (product?.pricingMethod) {
    //         case pricingMethod.STATIC:
    //             currentPrice = product?.price[0]
    //             break;
    //         case pricingMethod.COLOR:
    //             currentPrice = product?.price.find(p =>
    //                 p?.color === item.color
    //             )
    //             break;
    //         case pricingMethod.MATERIAL:
    //             currentPrice = product?.price.find(p =>
    //                 p?.material === item.material
    //             )
    //             break;
    //         case pricingMethod.SIZE:
    //             currentPrice = product?.price.find(p =>
    //                 p?.size === item.size
    //             )
    //             break;
    //         case pricingMethod.MATERIAL_COLOR:
    //             currentPrice = product?.price.find(p =>
    //                 p?.material === item.material &&
    //                 p?.color === item.color
    //             )
    //             break;

    //         case pricingMethod.MATERIAL_SIZE:
    //             currentPrice = product?.price.find(p =>
    //                 p?.material === item.material &&
    //                 p?.size === item.size
    //             )
    //             break;
    //         case pricingMethod.SIZE_COLOR:
    //             currentPrice = product?.price.find(p =>
    //                 p?.size === item.size &&
    //                 p?.color === item.color
    //             )
    //             break;

    //         case pricingMethod.MATERIAL_SIZE_COLOR:
    //             currentPrice = product?.price.find(p =>
    //                 p?.material === item.material &&
    //                 p?.size === item.size &&
    //                 p?.color === item.color
    //             )
    //             break;
    //         case pricingMethod.QUANTITY:
    //             currentPrice = product?.price.find(p =>
    //                 p?.min <= item.quantity &&
    //                 p?.max >= item.quantity
    //             );
    //             break;

    //         default:
    //             currentPrice = { priceValue: item.productPrice }
    //             break;
    //     }
    //     // console.log('product', product)

    //     return currentPrice?.priceValue
    // }

    const itemPrice = (item, index) => {
        const discount = getDiscount(item, index)
        const quantity = getQuantity(item, index)
        // const price = handlePriceChange(item)
        const price = item?.productPrice
        return price * quantity * (100 - discount) / 100;
    }

    const getTotalPrice = () => {
        return cart?.reduce((total, item, index) => total += itemPrice(item, index), 0)
    }

    const totalPrice = getTotalPrice()

    const formatPrice = (price) => {
        // return new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency: 'USD'
        // }).format(price);
        return `${price.toFixed(2)}`
    }

    return (
        <div className='transactionBill '>
            <Container className='border-top border-bottom border-start border-end border-black'>
                <Row className=' tableHeaderRow'>
                    <div className="d-none d-md-block col-md-2 tableHeaderCell border-end border-black">ID</div>
                    <div className="col-6 col-md-7 tableHeaderCell border-end border-black">DESCRIPTION</div>
                    <div className="col-3 col-md-2 tableHeaderCell border-end border-black">PRICE *QTY /DISC</div>
                    {/* <div className="col-1 tableHeaderCell border-end border-black">QTY</div> */}
                    {/* <div className="col-2 tableHeaderCell border-end border-black">DISCOUNT</div> */}
                    <div className="col-3 col-md-1 tableHeaderCell">TOTAL</div>
                </Row>
                {
                    cart?.map((item, index) => <>
                        <Row key={item?.productKey + index} className=' tableRow border-top border-black'>
                            <div className="d-none d-md-block col-md-2 tableCell border-end border-black d-flex align-items-center">  {/* ID */}
                                <span className=' productKey '>
                                    {item?.productKey}
                                </span>
                                <Container className=' productContainer' >
                                    {/* <ProductWindow item={item} product={products.find(p => p?._id === item?.productId)} /> */}
                                </Container>

                            </div>
                            <div className="col-6 col-md-7 tableCell border-end border-black">  {/* Description */}
                                {itemDescriptionTag(item, index)}
                            </div>
                            <div className="col-3 col-md-2 tableCell border-end border-black">  {/* Price */}
                                {/* {formatPrice(handlePriceChange(item))} *{getQuantity(item, index)} /-{getDiscount(item, index)}% */}
                                {formatPrice(item?.productPrice)} *{getQuantity(item, index)} /-{getDiscount(item, index)}%
                            </div>
                            {/* <div className="col-1 tableCell border-end border-black "> 
                                {getQuantity(item, index)}
                            </div> */}
                            {/* <div className="col-2 tableCell border-end border-black ">
                                {getDiscount(item, index)}
                            </div> */}
                            <div className="col-3 col-md-1 tableCell">  {/* Total */}
                                {formatPrice(itemPrice(item, index))}
                            </div>
                        </Row>
                    </>)
                }
                <Row className=' tableRow border-top border-black'>
                    <div className="col-12 tableCell"></div>
                </Row>
                <Row className=' tableRow border-top border-black'>
                    <div className="col-7 col-md-10 tableHeaderCell border-end border-black text-end">OVERALL DISCOUNT</div>
                    <div className="col-2 col-md-1 tableCell border-end border-black ">   {/* Overall Discount */}
                        {overallDiscount}
                    </div>
                    <div className="col-3 col-md-1 tableCell">  {/* Total Price Without Overall Discount */}
                        {formatPrice(totalPrice)}
                    </div>
                </Row>
                <Row className=' tableRow border-top border-black'>
                    <div className="col-9 col-md-11 tableHeaderCell border-end border-black text-end">DELIVERY</div>
                    <div className="col-3 col-md-1  tableCell border-black ">  {/* Delivery */}
                        {deliveryCost}
                    </div>
                </Row>
                <Row className=' tableRow border-top border-black'>
                    <div className="col-9 col-md-11 tableHeaderCell border-end border-black text-end">TOTAL</div>
                    <div className="col-3 col-md-1 tableCell">  {/* Total Price With Overall Discount */}
                        {formatPrice(totalPrice * (100 - overallDiscount) / 100 + deliveryCost)}
                    </div>
                </Row>
            </Container>

            {/* {console.table(transaction?.cart)} */}
        </div>
    )


}

// const getProductPrice = (product, material, size, color, index) => {
//     // console.log('PricingMethod', product?.pricingMethod)
//     let currentPrice = { priceValue: 0 }
//     switch (product?.pricingMethod) {
//         case pricingMethod.STATIC:
//             currentPrice = product?.price[0]?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.COLOR:
//             currentPrice = product?.price.find(p =>
//                 p?.color === color
//             )?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.MATERIAL:
//             currentPrice = product?.price.find(p =>
//                 p?.material === material
//             )?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.SIZE:
//             currentPrice = product?.price.find(p =>
//                 p?.size === size
//             )?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.MATERIAL_COLOR:
//             currentPrice = product?.price.find(p =>
//                 p?.material === material &&
//                 p?.color === color
//             )?.priceValue.toFixed(2)
//             break;

//         case pricingMethod.MATERIAL_SIZE:
//             currentPrice = product?.price.find(p =>
//                 p?.material === material &&
//                 p?.size === size
//             )?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.SIZE_COLOR:
//             currentPrice = product?.price.find(p =>
//                 p?.size === size &&
//                 p?.color === color
//             )?.priceValue.toFixed(2)
//             break;

//         case pricingMethod.MATERIAL_SIZE_COLOR:
//             currentPrice = product?.price.find(p =>
//                 p?.material === material &&
//                 p?.size === size &&
//                 p?.color === color
//             )?.priceValue.toFixed(2)
//             break;
//         case pricingMethod.QUANTITY:
//             currentPrice = `${product?.price[index]?.priceValue.toFixed(2)} [<=${product?.price[index]?.max}]`
//             // currentPrice = product?.price[0]?.priceValue.toFixed(2)
//             break;

//         default:
//             currentPrice = { priceValue: product?.price[0]?.priceValue.toFixed(2) }
//             break;
//     }

//     return `$${currentPrice}`
// }