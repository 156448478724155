import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"

const getProductsByKeys = (keys) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getProductsByKeys'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ keys })
        })
            .then(res => res.json())
            .then(json => {
                json?.success ? resolve(json?.data) : reject(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

export {
    getProductsByKeys
}