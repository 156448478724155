import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import reduxActionTypes from '../../../enums/reduxActionTypes'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../../actions/profileAction'
import Cookies from 'js-cookie'

const ChangePass = ({user}) => {


    const [oldpwd, setoldpwd] = useState()
    const [newpwd, setnewpwd] = useState()
    const [verifypwd, setverifypwd] = useState()
    const dispatch = useDispatch()

    const checkPassword = () => {
        // console.log(oldpwd , user.password , newpwd ,verifypwd )
        return oldpwd !=='' && newpwd !=='' && newpwd === verifypwd
    }

    const editUser = () => {
        if(!checkPassword()) {
            console.log("Invalid data")
            return 
        }

        const data = {
            oldPassword: oldpwd,
            newPassword: newpwd,
        }
        updateUser(data)
            .then(resolve => {
                console.log("user updated")
                dispatch({ type: reduxActionTypes.UPDATEUSER, data })
                // Cookies.set("user",JSON.stringify( {email:user.email,password:newpwd}))
                // console.log("updated User: ", user)
                setnewpwd('')
                setoldpwd('')
                setverifypwd('')

            }, reject => {
                console.error(reject.msg)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <div>
            <Row className="">
                <Col xs="12">
                    <div className="pt-2 ">old password:</div>
                    {/* <div className="p-1 item-delete-edit">{user.email}</div> */}
                    <input
                        type="password"
                        className="input-form d-block px-3 input-profile "
                        onChange={(e) => setoldpwd(e.target.value)}
                        value={oldpwd}

                    />

                </Col>
            </Row>

            <Row className="">
                <Col xs="12">
                    <div className="pt-2 ">new password:</div>
                    <input
                        type="password"
                        className=" d-block px-3 input-profile "
                        onChange={(e) => setnewpwd(e.target.value)}
                        value={newpwd}

                    />

                </Col>
            </Row>

            <Row className="">
                <Col xs="12">
                    <div className="pt-2">verify password:</div>
                    <input
                        type="password"
                        className="input-form d-block px-3 input-profile"
                        onChange={(e) => setverifypwd(e.target.value)}
                        value={verifypwd}

                    />

                </Col>
            </Row>




            <Row className=' pb-2 mt-1'>
                <Col xs="12" className="d-flex justify-content-center ">
                    <button className=" d-inline mt-2 button-profile" onClick={editUser}>Save</button>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePass
