

import { Col, Container, Row } from "react-bootstrap";

// import CardProductsContainer from "./CardProductsContainer";
// import Pagination from "../../components/Utility/Pagination";
// import SideFilter from "../../components/Utility/SideFilter";
import SearchCountResult from "../../Component/store/SearchCountResult";

// import { getProducts } from "../../redux/actions/AllProductsActions";
// import Card from "../../components/Card/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { getNProductsByCategory, getProductsNumberByCategory } from "../../actions/storeAction";
import { useParams } from "react-router-dom";
import ProductCard from "../../Component/store/product/ProductCard";
import CategoryHeader from "../../Component/store/catergory/CaregoryHeader";
import reduxActionTypes from "../../enums/reduxActionTypes";
import StoreNavBar from "../../Component/NavBar/StoreNavBar";
// import { getCategorys } from "../../redux/actions/AllCategorysAction";



const ShopProductsPage = () => {

    const user = useSelector(state => state.user)
    const { subCat } = useParams()
    const dispatch = useDispatch();
    const productsData = useSelector((state) => state.products);
    // const [products, setProducts] = useState([]);
    const products = useSelector(state => state.products)
    const [productsCount, setProductsCount] = useState(() => 0)
    // const [pro, setPro] = useState(products);
    const [searchVal, setSearchVal] = useState('');

    const itemsPerPage = 8;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // const currentItems = pro.slice(itemOffset, endOffset);
    const [pageCount, setPageCount] = useState(() => 0)
    const [currentPage, setCurrentPage] = useState(() => 1)
    // const pageCount = 10;

    useEffect(() => {
        console.log("from shopProductsPage: " + user.email)
        window.scrollTo(0, 0);
        getProductsNumberByCategory(subCat)
            .then((res) => {
                setProductsCount(res.count)
                setPageCount(Math.ceil(res.count / itemsPerPage))
            })
            .catch(error => console.error(error))
    }, []);

    useEffect(() => {
        getNProductsByCategory(subCat, currentPage, itemsPerPage)
            .then(res => {
                // setProducts(res.data)
                dispatch({ type: reduxActionTypes.SETPRODUCTS, products: res.data })
                console.log(res.data)

            })
            .catch(error => console.error(error))

        return () => {

        }
    }, [currentPage])



    const filterbyCategory = (cat) => {
        // if (cat === "all" || cat === "") {
        //     setPro(products)
        // }
        // else {

        //     const newArr = products.filter((item) => item.productCategory === cat)
        //     setPro(newArr)
        // }
    }

    const filterByTitle = (tit) => {
        // if (tit !== "") {
        //     const newArr = products.filter((item) => item.productTitle.includes(tit))
        //     setPro(newArr)
        // }
        // else { setPro(products) }
    }






    const handlePageClick = (event) => {
        // console.log(event.selected)
        setCurrentPage(event.selected + 1)
    };

    // console.log(pageCount)
    // console.log(productsCount)
    return (<>
    <StoreNavBar/>
        <div style={{ minHeight: "670px" }}>
            <CategoryHeader />
            <Container>
                <SearchCountResult title={`${products.length}`} setSearch={setSearchVal} filterByTitle={filterByTitle} />
                <Row className="d-flex flex-row">
                    {/* justify-content-between */}
                    <Row className="my-2 d-flex ">
                        {products.map((product) => (
                            <ProductCard key={product._id} product={product} />
                        ))}
                    </Row>
                </Row>

                {pageCount > 0 && <div style={{ marginTop: '20px', marginBottom: '20px' }}><ReactPaginate
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="prev"
                    containerClassName={"pagination justify-content-center p-3"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
                </div>
                }

            </Container>
        </div>
        </>
    );
};

export default ShopProductsPage;
