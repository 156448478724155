import React, { useEffect, useState } from "react";
// import kk from '../../../public/img/Upgrade-Blog-6-17.png'
import "./services.css"
import ServiceCard from "./ServiceCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
// import { ProductSlider } from "./productSilder/ProductSlider";
import ProductSlider2 from "./productSilder/ProductSlider2";
import CategoryCartSceleton from "../../Sceleton/CategoryCartSceleton";
import ProductSliderSceleton from "./productSilder/ProductSliderSceleton";

// import mainCategories from '../../actions/services'

const Services = () => {


  const mainCategories = useSelector(state => state.mainCategories)
  // useEffect(() => {
  //   console.log('mainCategories', mainCategories)
  // }, [mainCategories])

  const isLoading = mainCategories.length === 0

  return (
    <div>
      <section id="services" >
        <div class="container">
          <div class="row mb-3">
            <div class="col-md-8 mx-auto text-center">
              {/* <h6 class="text-primary">SERIVCES</h6> */}
              <h1 className="service-title0">Our Categories</h1>
              {/* <p className="service-descreption">
                Welcome to our website, where innovation meets expertise! We are dedicated to providing trusted and top-quality services tailored to meet your unique needs. We are here to turn your vision into reality.
              </p> */}
            </div>
          </div>

          <div className="CategoryRow" >
            {isLoading && [1, 2, 3, 4, 5].map(t => {
              return < CategoryCartSceleton key={t} />
            })
            }
            {mainCategories.map(t => {
              if (t._id !== "ALL")
                return <ServiceCard key={'mc-' + t._id} {...t} />
            })}




          </div>
          {isLoading &&
            < ProductSliderSceleton />}
          {mainCategories.map(t => {
            if (t._id !== "ALL")
              return <ProductSlider2 key={t.key} category={t} />
          })}
        </div>
      </section>
    </div>


  );
};

export default Services;
