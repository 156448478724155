import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"

const addToContact = (name, email, subject, message) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/addToContact'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({name, email, subject, message})
        })
            .then(res => res.json())
            .then(json => {
                resolve( json )
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

export {
    addToContact
}
