import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './categorycard.css'
const CategoryCard = ({ background, image, name, description, parent , _id}) => {
    image = require('../../../assets/images/c1.jpeg')  ///
    return (
        <Col
            xs="6"
            sm="6"
            md="4"
            lg="2"
            className="my-4 d-flex justify-content-around ">
            <Link to={`/store/PV/${_id}`} >
                <div className="allCard mb-3 ">
                    <div
                        className="categoty-card "
                        style={{ backgroundColor: `${background}` }}></div>{" "}
                    <img alt="zcv" src={image} className="categoty-card-img" />
                    <p className="categoty-card-text my-2">{name}</p>
                </div>
            </Link>
        </Col>
    )
}

export default CategoryCard