import Cookies from "js-cookie"
import { getBaseApiURL } from "./urlService"

const getSubCategories = (cat) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getCategories'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get("token")}`
            },
            body: JSON.stringify({ parent: cat })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json.data)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getProductsNumberByCategory = (cat) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getProductsNumber'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({ category: cat })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ count: json.count })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getProductsNumber = (cat, parent, searchQuery) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getProductsNumber'
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get("token")}`
            },
            body: JSON.stringify({ category: cat, parent, searchQuery })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ count: json.count })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getNProductsByCategory = (cat, page, number) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getNProductsByCategory'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ category: cat, page, number })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json.data })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getNProducts = (cat, page, number, parent, sort, searchQuery) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getNProducts'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ category: cat, page, number, parent, sort, searchQuery })
        })
            .then(res => res.json())
            .then(json => {
                json?.success ? resolve({ data: json.data }) : reject(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getProductById = (productId) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getProductById'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json.data })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}


const like = (productId) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/addToFavorite'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ result: json.success })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const unLike = (productId) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/removeFromFavorite'
        fetch(url, {
            method: 'delete',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ result: json.success })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getUserRating = (productId) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getUserRating'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}


const rateProduct = (productId, rate) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/rateProduct'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId, rate })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getAllProductsTitles = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getAllProductsTitles'
        fetch(url, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.success)
                    resolve({ data: json.data })
                else
                    reject({ data: [] })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}
const getAllFavorite = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getAllFavorite'
        fetch(url, {
            // credentials: 'include',
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                if(json.success) resolve({ data: json.data })
                reject({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}
const getAllCartItems = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getAllCartItems'
        fetch(url, {
            // credentials: 'include',
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                if(json.success) resolve({ data: json.data })
                reject(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const addToCart = (productId, color, quantity, size, material, price) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/addToCart'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ productId, color, quantity, size, material, price })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const removeFromCart = (cartId) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/removeFromCart'
        fetch(url, {
            method: 'delete',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({ cartId })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const addToTransaction = (cart, comment, clientName, city, location, phone, delivery) => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/addToTransaction'
        fetch(url, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            },
            body: JSON.stringify({cart, comment, clientName, city, location, phone, delivery })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getFavoriteCount = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getFavoriteCount'
        fetch(url, {
            // credentials: 'include',
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}
const getCartCount = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getCartCount'
        fetch(url, {
            // credentials: 'include',
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

// const changeStateByUser = (_id,status) => {
//     return new Promise((resolve, reject) => {
//         const url = getBaseApiURL() + '/changeStateByUser'
//         fetch(url, {
//             method: 'PATCH',
//             // credentials: 'include',
//             headers: {
//                 "Content-Type": 'application/json',
//                 Authorization: `Bearer ${Cookies.get('token')}`
//             },
//             body: JSON.stringify({ _id ,status })
//         })
//             .then(res => res.json())
//             .then(json => {
//                 json?.success? resolve(json) : reject(json)
//             })
//             .catch(error => {
//                 reject(new Error(error))
//             })
//     })
// }

const getAllSlider = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getAllSlider'
        fetch(url, {
            // credentials: 'include',
            headers : {
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                if(json.success) resolve({ data: json.data })
                reject({ json })
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

const getMainCategories = () => {
    return getSubCategories('ROOT')
}

const getAllCategories = () => {
    return new Promise((resolve, reject) => {
        const url = getBaseApiURL() + '/getAllCategories'
        fetch(url, {
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${Cookies.get('token')}`
            }
        })
            .then(res => res.json())
            .then(json => {
                json?.success ? resolve(json.data): reject(json)
            })
            .catch(error => {
                reject(new Error(error))
            })
    })
}

export {
    getSubCategories,
    getProductsNumberByCategory,
    getProductsNumber,
    getProductById,
    getNProductsByCategory,
    getNProducts,
    like,
    unLike,
    getUserRating,
    rateProduct,
    getAllProductsTitles,
    getAllFavorite,
    getAllCartItems,
    addToCart,
    removeFromCart,
    addToTransaction,
    getFavoriteCount,
    getCartCount,
    // changeStateByUser,
    getAllSlider,
    getMainCategories,
    getAllCategories
}