import "./signup.css"
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import role from "../../enums/role";
import { signUp } from "../../actions/signUpActions";
import { useDispatch } from "react-redux";
import reduxActionTypes from '../../enums/reduxActionTypes'
// import { useDispatch, useSelector } from "react-redux";
// import { signupaction } from "../../redux/actions/SignUpAction";

export default function SignUp({ setVerify }) {

    // const [showPassword, setShowPassword] = useState(false);
    // const navigate = useNavigate();
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [location, setlocation] = useState('')
    const [notification, setnotification] = useState(false)
    const dispatch = useDispatch()

    const [msgFName, setmsgFName] = useState("");
    const [msgLName, setmsgLName] = useState("");
    const [msgemail, setmsgEmail] = useState("");
    const [msgPassword, setmsgPassword] = useState("");
    const [msgconfirmPassword, setmsgconfirmPassword] = useState("");
    const [msgphone, setmsgPhone] = useState("");
    const [msglocation, setmsglocation] = useState('')


    // const handlePhotoChange = (e) => {
    //     const file = e.target.files[0];
    //     const fileName = file ? file.name : '';
    //     setphoto(fileName);
    // };
    // const loading = useSelector((state) => state.signup.loading);
    // const success = useSelector((state) => state.signup.success);
    // const error = useSelector((state) => state.signup.error);
    // const dispatch = useDispatch();
    const initValidetor = () => {
        setmsgEmail("");
        setmsgFName("");
        setmsgLName("");
        setmsgPassword("");
        setmsgPhone("");
        setmsgconfirmPassword("");
        setmsglocation("");
    }

    const isValide = () => {
        let b = true;
        initValidetor();

        if (!/^[A-Za-z]+$/.test(FName)) {
            setmsgFName("using only character");
            b = false;
        }
        if (FName === "") {
            setmsgFName("first name is empty");
            b = false;
        }

        if (!/^[A-Za-z]+$/.test(LName)) {
            setmsgLName("using only character");
            b = false;
        }
        if (LName === "") {
            setmsgLName("last name is empty");
            b = false;
        }


        if (!/^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/.test(email)) {
            setmsgEmail("invalid email ");
            b = false;
        }
        if (email === "") {
            setmsgEmail("email is empty");
            b = false;
        }


        if (!/^(?=.{8,})/.test(Password)) {
            setmsgPassword("password lenght<8");
            b = false;
        }
        if (Password === "") {
            setmsgPassword("password is empty");
            b = false;
        }


        if (confirmPassword !== Password) {
            setmsgconfirmPassword("confirmation password != password");
            b = false;
        }
        if (confirmPassword === "") {
            setmsgconfirmPassword("password confirmatin is empty");
            b = false;
        }

        if (!/^\d{8,}$/.test(phone)) {
            setmsgPhone("phon number invalid");
            b = false;
        }
        if (phone === "") {
            setmsgPhone("phon is empty");
            b = false;
        }

        if (!/^[A-Za-z]+$/.test(location)) {
            setmsglocation("using only character");
            b = false;
        }
        if (location === "") {
            setmsglocation("location is empty");
            b = false;
        }
        return b;
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!isValide()) return
        const formData = {
            name: FName + ' ' + LName,
            email,
            password: Password,
            role: role.CLIENT,
            location,
            phone,
            notification
        };
        signUp(formData).then(
            resolve => {
                {
                    dispatch({ type: reduxActionTypes.SETUSER, user: formData })
                    setVerify(true)
                }
            },
            reject => {
                console.log(reject?.msg)
                setmsgEmail(reject?.msg)
            }
        )
        // dispatch(signupaction(formData));
    };



    return (
        <div className="signup">
            <h2 className="mb-4 animate__animated animate__fadeInDown ">Sign Up</h2>
            <div className="form-group animate__animated animate__fadeInLeft">

                {/* {success && (navigate(0), navigate("/StorePage"))}
                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error.message.split(",")[0]}
                    </div>
                )} */}

                <label htmlFor="firstName" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>First Name </label> <label className="error-message">{msgFName}</label>
                <input
                    type="text"
                    id="firstName"
                    className="form-control"
                    style={{
                        marginBottom: "20px", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        backgroundColor: "rgb(0,0,0,0.5)", color: "white"
                    }}
                    placeholder="Enter your first name"
                    value={FName}
                    onChange={(e) => setFName(e.target.value)}
                />

                <label htmlFor="lastName" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Last Name</label>  <label className="error-message">{msgLName}</label>
                <input
                    type="text"
                    id="lastName"
                    style={{
                        marginBottom: "20px", backgroundColor: "rgb(0,0,0,0.5)",
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white"
                    }}
                    className="form-control"
                    placeholder="Enter your last name"
                    value={LName}
                    onChange={(e) => setLName(e.target.value)}
                />

                <label htmlFor="email" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Email</label> <label className="error-message">{msgemail} </label>
                <input
                    type="email"
                    id="email"
                    style={{
                        marginBottom: "20px", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        backgroundColor: "rgb(0,0,0,0.5)", color: "white"
                    }}
                    className="form-control"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="password" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Password</label><label className="error-message">{msgPassword} </label>
                <input
                    type={"password"}
                    id="password"
                    style={{
                        marginBottom: "20px", backgroundColor: "rgb(0,0,0,0.5)",
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white"
                    }}
                    className="form-control"
                    placeholder="Enter your password"
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                />


                <label htmlFor="password" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>confirm Password</label><label className="error-message"> {msgconfirmPassword}</label>
                <input
                    type={"password"}
                    id="confirmpassword"
                    style={{
                        marginBottom: "20px", backgroundColor: "rgb(0,0,0,0.5)",
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white"
                    }}
                    className="form-control"
                    placeholder="Enter your password"
                    value={confirmPassword}
                    onChange={(e) => setconfirmPassword(e.target.value)}
                />

                <label htmlFor="phone" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>Phone </label><label className="error-message">{msgphone} </label>
                <input
                    type='tel'
                    id="phone"
                    style={{
                        marginBottom: "20px", backgroundColor: "rgb(0,0,0,0.5)",
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white"
                    }}
                    className="form-control"
                    placeholder="+961 71740318"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />

                <label htmlFor="location" style={{ marginBottom: "10px", color: "#25485c", textShadow: "none", fontWeight: "bold" }}>City</label> <label className="error-message">{msglocation} </label>
                <input
                    type='text'
                    id="location"
                    style={{
                        marginBottom: "", backgroundColor: "rgb(0,0,0,0.5)",
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', color: "white"
                    }}
                    className="form-control"
                    placeholder="Tripoli"
                    value={location}
                    onChange={(e) => setlocation(e.target.value)}
                />

                <div className="emailNothification">
                    <input
                    id="emailNothification"
                        type="checkbox"
                        style={{ width: "20px", height: "20px", color: 'white', marginRight:"5px"}}
                        onChange={() => setnotification(preValue => !preValue)}
                    />
                    <label htmlFor="emailNothification" style={{ color: "#25485c", textShadow: "none", fontWeight: "bold", cursor: "pointer" }}> Recive email notification</label>
                </div>

                {/* <label htmlFor="profilePhoto" style={{ marginBottom: "10px", marginTop: "30px" }}>Photo for your profile:</label>
                <input type="file" id="profilePhoto" style={{ marginBottom: "20px", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: "rgb(0,0,0,0.5)", color: "white" }}
                    className="form-control" onChange={handlePhotoChange} /> */}
            </div>

            <button onClick={handleSubmit} type="submit" className="btn btn-primary" disabled={false}>
                {"Sign Up"}
            </button>




            <div style={{ height: '213px' }}></div>
        </div>
    );
}
