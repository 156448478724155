import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import head from '../../assets/category/head.png';
import logo_1 from "../../assets/logo/logo 1 without back.png";
import logo_2 from "../../assets/logo/logo 2 without back.png";
import './navbar.css';


const GuestNavbar = () => {
  const [isHovered, setIsHovered] = useState(() => false)

  const isLogedin = useSelector(state => state.isLogedin)
  const [isOpen, setisOpen] = useState(false)

  const toggleNavBar = () => {
    setisOpen(!isOpen)
  }
  const closeNavBar = () => {
    setisOpen(false)
  }

  return (

    <>
      <nav className="navbar navbar-expand-md bg-light sticky-top" >
        < div className="container">
          <Link className="logo navbar-brand fw-bold" to="/">
            <img src={isHovered ? logo_2 : logo_1} className="img-fluid" alt="" onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => setIsHovered(false)} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavBar}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto fw-semibold">
              <li className="nav-item mt-1">
                <Link className="nav-link pe-3" to="/StorePage2">Store</Link>
              </li>
              <li className="nav-item mt-1">
                <a className="nav-link pe-3" href="/#services">Services</a>
              </li>
              <li className="nav-item mt-1">
                <a className="nav-link pe-3" href="/#features">Features</a>
              </li>


              <li className="nav-item mt-1">
                <a className="nav-link pe-3" href="/#contact">Contact</a>
              </li>


              <li className="nav-item ">
                {/* btn-primary */}
                {/* onClick={() => logout(dispatch, navigate)} */}

                {
                  !isLogedin ?
                    // <Link to="/login" className="btn btn-primary ms-3 fw-bold px-3 py-2 join-btn">Join Us</Link> :
                    <Link to="/login" className="nav-link mt-1 joinus-button">Join Us</Link> :
                    <div>
                      <Link to='/profile'>
                        <span className="nav-link pe-3 mt-1 d-block d-md-none">Profile</span>
                        <img
                          src={head}
                          className="account-logo d-none d-md-block"
                        // onClick={() => setshowDropDown(pre => !pre)}
                        />
                      </Link>
                      {/* {showDropDown && <HomeDropDown />}
                      <ul className="d-block d-md-none navbar-nav ms-auto fw-semibold">
                        <li className="nav-item ">
                          <a className="nav-link" href="#">Profile</a>
                        </li> */}
                      {/* <li className="nav-item ">
            <a className="nav-link" href="#">Cart</a>
          </li> */}
                      {/* <li className="nav-item ">
                          <a className="nav-link" onClick={() => logout(dispatch, navigate)}>Logout</a>
                        </li>
                      </ul> */}
                    </div>
                }

              </li>
            </ul>
          </div>

        </div>
      </nav >
      {isOpen && (
        <div className=' navbar-overlay' onClick={closeNavBar}></div>
      )}

    </>

  );
};

export default GuestNavbar;