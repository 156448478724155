import React from 'react'
import { Link } from 'react-router-dom'
import arrow from '../../assets/category/arrow.png'
import testii from '../../assets/test/1.png'

const ServiceCard = ({ _id, image, name, description, link }) => {


  return (
    // col-lg-4  col-6 
    // <div className="serviceCard  col-2 ">
    //   <Link to={`/StorePage2?filterCategory=${encodeURIComponent(_id)}&filterSubCategory=ALL&searchQuery=`} className='service-link '>
    //     <div className="service ">
    //       <div className="card-img">
    //         <img src={image?.url} />
    //       </div>
    //       <h4 className=" service-title" >{name}</h4>
    //     </div>
    //     <p className='mt-1 service-descreption'>
    //       {description}
    //     </p>
    //     <div>
    //       <img src={arrow} className='service-arrow' />Read more
    //     </div>
    //   </Link>
    // </div>

<div className="serviceCard pb-3 ">
 <Link to={`/StorePage2?filterCategory=${encodeURIComponent(_id)}&filterSubCategory=ALL&searchQuery=`} > 
             <img src={image?.url} className='image-cart'/>
  </Link>         
</div>
  )
}

export default ServiceCard
