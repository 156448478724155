import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyEmail } from '../../actions/signUpActions'
import { useNavigate } from 'react-router-dom'
import Counter from './Counter'
import Cookies from 'js-cookie'
import reduxActionTypes from '../../enums/reduxActionTypes'

const Verification = () => {
    const [code, setcode] = useState(null)
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            email: user.email,
            code
        }
        verifyEmail(data).then(
            resolve => {
                console.log('Verfied successfully')
                // dispatch({type:reduxActionTypes.SETUSER, user: resolve.data})
                dispatch({ type: reduxActionTypes.LOGIN })
                // Cookies.set('user', JSON.stringify({ _id: resolve.data._id, email: user.email, password: user.password }), { expires: 365 })
                Cookies.set('token', resolve.token, { expires: 365 })
                navigate('/')
            },
            reject => {
                console.log('Wrong code')
            }
        )
    }

    return (
        <>
            <h2 className="mb-4 animate__animated animate__fadeInDown ">Verification</h2> 
            <p className='animate__animated animate__fadeInLeft' style={{color: "red" , textShadow: "none" }}>Please check the span box for verification message!</p>
            <div className="form-group animate__animated animate__fadeInLeft">

                {/* {success && (navigate("/StorePage"))} */}

                {/* {error && (
        <div className="alert alert-danger" role="alert">
          {error.message.split(",")[0]}
        </div>
      )} */}

                <label htmlFor="code" style={{ marginBottom: "10px", }}>Email</label>
                <input
                    type="text"
                    id="code"
                    style={{
                        marginBottom: "20px", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
                        , backgroundColor: "rgb(0,0,0,0.5)", color: "white",
                        letterSpacing: '12px', textAlign: 'center', fontSize: '26px'
                    }}
                    className="form-control"
                    placeholder="_ _ _ _ _ _"
                    value={code}
                    onChange={(e) => setcode(e.target.value)}
                    autoFocus="true"
                />
                <Counter />


                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-primary"
                    disabled={false}>
                    Send
                </button>

                <div style={{ height: '300px' }}></div>
            </div>
        </>
    )
}

export default Verification
